import { Typography } from "antd";
import "./index.scss";
import React from "react";

const { Text, Title } = Typography;
const AboutUsComponent = () => {
  return (
    <div className="about-us-container">
      <div className="heading">
        <Title level={1}>About turfbooking.in</Title>
        <Text strong>
          At Turf Booking, we're passionate about connecting sports enthusiasts
          with the perfect turf for their needs. Whether you're a soccer
          aficionado, a cricket enthusiast, or simply enjoy a good game of
          ultimate frisbee, we've got you covered. Our platform is designed to
          make finding and booking turfs in your city a breeze.
        </Text>
      </div>
      <div className="section">
        <Title level={4}>What Sets Us Apart</Title>
        <Text>
          Unlike traditional methods of turf booking, which often involve
          endless phone calls and uncertainty about availability, Turf Booking
          offers a seamless online experience. With just a few clicks, you can
          explore a wide range of turfs in your area, complete with detailed
          information on timings, area, price per hour, and location.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Stay Updated</Title>
        <Text>
          We understand that the sports landscape is constantly evolving, which
          is why we're committed to keeping our data up-to-date. Our team works
          tirelessly to ensure that you have access to the latest information on
          all the famous turfs in your city. From newly opened facilities to
          special promotions, you'll find it all here.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Looking Ahead</Title>
        <Text>
          In the near future, we'll be rolling out exciting new features to
          enhance your turf booking experience even further. Soon, you'll be
          able to reserve your favorite turf directly through our website, with
          real-time availability and convenient booking slots.
        </Text>
      </div>
      <div className="section">
        <Title level={4}>Join Our Community</Title>

        <Text>
          Whether you're a seasoned athlete or just getting started, we invite
          you to join the Turf Booking community. Connect with fellow sports
          enthusiasts, share tips and tricks, and stay informed about all things
          turf-related.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Get in Touch</Title>
        <Text>
          Have questions or feedback? We'd love to hear from you! Feel free to
          reach out to our team at <a>info@turfbooking.in</a>. Thank you for
          choosing Turf Booking Let's play!
        </Text>
      </div>
    </div>
  );
};

export default AboutUsComponent;
