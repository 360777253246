import { CloseOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Button, Drawer, Modal } from "antd";
import React, { useState } from "react";
import LocationAutocomplete from "..";
import { useUserLocation } from "../LocationContext";
import "../location.scss";
import getUserLocation from "../../../utils/location";
import { useMediaQuery } from "react-responsive";
import { showAlertModal } from "../../alertModal";
import constants from "../../../utils/constants";

const MobileLocationSelector = () => {
  const [sortDrawerVisible, setSortDrawerVisible] = useState(false);
  const { userLocation, setUserLocation } = useUserLocation();

  const showSortDrawer = () => {
    setSortDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setSortDrawerVisible(false);
  };

  const handlePlaceSelected = (place: any) => {
    setUserLocation(place);
    onCloseDrawer();
  };
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const getCurrentLocation = () => {
    getUserLocation(setUserLocation, showAlertModal(isMobile));
    onCloseDrawer();
  };
  return (
    <>
      <div onClick={showSortDrawer} className="location-container">
        <span>
          <EnvironmentOutlined style={{ color: "#483285", fontSize: "20px" }} />{" "}
        </span>
        <span className="location-text"> {userLocation?.name ||constants.DEFAULT_CITY}</span>
      </div>
      <Drawer
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Select Location</span>
            <span onClick={() => onCloseDrawer()}>
              <CloseOutlined />
            </span>
          </div>
        }
        placement="bottom"
        closable={false}
        onClose={onCloseDrawer}
        open={sortDrawerVisible}
        height="60%"
      >
        <LocationAutocomplete onPlaceSelected={handlePlaceSelected} />
        <div>
          <Button onClick={getCurrentLocation} className="button">
            <div className="current-location">
         
            <svg xmlns="http://www.w3.org/2000/svg" fill="#483285" width="18" height="18" 
            viewBox="0 0 20 20" aria-labelledby="icon-svg-title- icon-svg-desc-" role="img" 
            ><title>current-location</title><path d="M13.58 10c0 1.977-1.603 3.58-3.58 3.58s-3.58-1.603-3.58-3.58c0-1.977 1.603-3.58 3.58-3.58v0c1.977 0 3.58 1.603 3.58 3.58v0zM20 9.52v0.96c0 0.265-0.215 0.48-0.48 0.48v0h-1.72c-0.447 3.584-3.256 6.393-6.802 6.836l-0.038 0.004v1.72c0 0.265-0.215 0.48-0.48 0.48v0h-0.96c-0.265 0-0.48-0.215-0.48-0.48v0-1.72c-3.575-0.455-6.375-3.262-6.816-6.802l-0.004-0.038h-1.74c-0.265 0-0.48-0.215-0.48-0.48v0-0.96c0-0.265 0.215-0.48 0.48-0.48v0h1.74c0.445-3.578 3.245-6.385 6.781-6.836l0.039-0.004v-1.72c0-0.265 0.215-0.48 0.48-0.48v0h0.96c0.265 0 0.48 0.215 0.48 0.48v0 1.72c3.584 0.447 6.393 3.256 6.836 6.802l0.004 0.038h1.72c0.265 0 0.48 0.215 0.48 0.48v0zM15.96 10c0-3.292-2.668-5.96-5.96-5.96s-5.96 2.668-5.96 5.96c0 3.292 2.668 5.96 5.96 5.96v0c3.292 0 5.96-2.668 5.96-5.96v0z"></path></svg>
            {/* </div> */}
            <span> Detect Current Location</span>
            </div>
                       <p>Using GPS</p>
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default MobileLocationSelector;
