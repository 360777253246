import { Modal } from "antd";

export const showAlertModal = ({ isMobile }: any) => {
  return () => {
    const modal = Modal.info({
      title: "Info",
      content: (
        <div>
          <p>
            You have blocked Turf Booking from tracking your location. To use
            this, change your location settings in the browser.
          </p>
          <a
            target="_blank"
            href={`${
              !isMobile
                ? "https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop"
                : "https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DAndroid"
            }`}
          >
            Click here to learn more about location settings.
          </a>
        </div>
      ),
      onOk() {},
    });

    // Return a function to destroy the modal
    return () => {
      modal.destroy();
    };
  };
};
