interface FeatureFlags {
  [key: string]: {
    showBookNow: boolean;
  };
}

const featureFlags:FeatureFlags = {
  indore: {
    showBookNow: true,
  },
  bhopal: {
    showBookNow: false,
  },
  dewas: {
    showBookNow: true,
  },
  ujjain: {
    showBookNow: true,
  },
  pune: {
    showBookNow: false,
  },
  mhow: {
    showBookNow: true,
  },
  mandsaur: {
    showBookNow: false,
  },
};

export default featureFlags;
