import React, { useState } from 'react';
import { Slider } from 'antd';
import { DISTANCE_RANGE_MAX } from '../constants/constants';

interface DistanceSliderRangeProps {
    distanceRange: number;
    handleDistanceChange: (value: number) => void;
  }
  

const DistanceSliderRange = ({ distanceRange,handleDistanceChange }:DistanceSliderRangeProps) => {
  return (
    <div>
      <h2>Filter Turfs by Distance</h2>
      <Slider
        min={0}
        max={DISTANCE_RANGE_MAX}
        onChange={handleDistanceChange}
        value={distanceRange}
       
      />
      <h3>Turfs within {distanceRange} km:</h3>
     
    </div>
  );
};

export default DistanceSliderRange;
