import React from "react";
import { Button, InputNumber } from "antd";
import "./durationCounter.scss";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { formatDuration } from "../../utils/formatDuration";

interface DurationCounterProps {
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
}
const DurationCounter = ({ duration, setDuration }: DurationCounterProps) => {
  const handleIncrement = () => {
    // 1440 mins = 24hrs
    if (duration < 1440) setDuration((prevDuration) => prevDuration + 30);
  };

  const handleDecrement = () => {
    if (duration > 60) {
      setDuration((prevDuration) => prevDuration - 30);
    }
  };

  return (
    <div className="duration-counter">
      <Button
        type="primary"
        shape="circle"
        onClick={handleDecrement}
        disabled={duration <= 60}
      >
        <MinusOutlined />
      </Button>
      <InputNumber
        // min={60}
        // max={480} // Maximum duration, you can adjust it as needed
        step={30} // Increment by 30 minutes
        value={duration}
        formatter={(value) => formatDuration(value)}
        onChange={(value) => setDuration(value ?? 60)}
        readOnly
      />
      <Button
        type="primary"
        shape="circle"
        onClick={handleIncrement}
        disabled={duration >= 1440}
      >
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default DurationCounter;
