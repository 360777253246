export default {
  HOST_URL: process.env.REACT_APP_HOST_URL || "",
  HOST_DOMAIN: process.env.REACT_APP_HOST_DOMAIN || "",
  PROTOCOL: process.env.NODE_ENV === "development" ? "http" : "https",
  BUISNESS_NUMBER: "9238764656",
  HIDE_BOOK_NOW: ["bhopal", "ujjain", "pune","mandsaur"],
  DEFAULT_CITY: "indore",
  CITIES: ["indore", "bhopal", "dewas", "ujjain", "pune", "mhow","mandsaur"],
};

