import { TileCardDetails } from "../pages/Interfaces";

interface Location {
  latitude: number;
  longitude: number;
}

export const calculateDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) => {
  const earthRadius = 6371; // Earth's radius in kilometers

  const radLat1 = (Math.PI * lat1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const deltaLat = (Math.PI * (lat2 - lat1)) / 180;
  const deltaLng = (Math.PI * (lng2 - lng1)) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c; // Distance in kilometers
};

export const sortTurfByLocation = (
  turfDetails: TileCardDetails[],
  userLocation: Location | null
) => {
  if(!!userLocation){
  return turfDetails.slice().sort((a, b) => {
    const locationA = a.data.location;
    const locationB = b.data.location;
    const distanceA = calculateDistance(
      userLocation.latitude,
      userLocation.longitude,
      Number(locationA.latitude),
      Number(locationA.longitude)
    );

    const distanceB = calculateDistance(
      userLocation.latitude,
      userLocation.longitude,
      Number(locationB.latitude),
      Number(locationB.longitude)
    );

    return distanceA - distanceB;
  });
}
return turfDetails
};


// import { TileCardDetails } from "../pages/Interfaces";

// interface Location {
//   latitude: number;
//   longitude: number;
// }

// export const calculateDistance = (
//   coords1: [number, number],
//   coords2: [number, number]
// ) => {
//   const earthRadius = 6371; // Earth's radius in kilometers

//   const [lat1, lng1] = coords1;
//   const [lat2, lng2] = coords2;

//   const toRadians = (degree: number) => (Math.PI * degree) / 180;

//   const radLat1 = toRadians(lat1);
//   const radLat2 = toRadians(lat2);
//   const deltaLat = toRadians(lat2 - lat1);
//   const deltaLng = toRadians(lng2 - lng1);

//   const a =
//     Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
//     Math.cos(radLat1) *
//       Math.cos(radLat2) *
//       Math.sin(deltaLng / 2) *
//       Math.sin(deltaLng / 2);

//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//   return earthRadius * c; // Distance in kilometers
// };

// export const sortTurfByLocation = (
//   turfDetails: TileCardDetails[],
//   userLocation: Location
// ) => {
//   return turfDetails.slice().sort((a, b) => {
//     const { latitude: lat1, longitude: lng1 } = a.data.location;
//     const { latitude: lat2, longitude: lng2 } = b.data.location;

//     const coords1: [number, number] = [userLocation.latitude, userLocation.longitude];
//     const coords2: [number, number] = [Number(lat1), Number(lng1)];

//     const distanceA = calculateDistance(coords1, coords2);
//     const distanceB = calculateDistance(coords1, coords2); 

//     return distanceA - distanceB;
//   });
// };