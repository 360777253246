import "./index.scss";

import React, { useState, useEffect } from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ImagesCarouselProps } from "../../pages/Interfaces";
import { Flex, Rate, Typography } from "antd";
import { MobileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const { Text, Title } = Typography;
const ImagesCarousel: React.FC<ImagesCarouselProps> = ({
  name,
  imageUrl,
  imgHeight,
  isHovered,
  turf,
  contact,
  starRating,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const rating = starRating ? parseFloat(starRating) : 0;
  const imgMeta = `${turf?.name} | ${turf?.city}  | TurfBooking`;

  const renderImages = () => {
    return imageUrl?.map((image, index) => (
      <div key={index} className="carousal-image">
        <LazyLoadImage
          alt={imgMeta}
          title={imgMeta}
          src={image}
          height={imgHeight}
          className="image"
        />
      </div>
    ));
  };
  useEffect(() => {
    if (isHovered) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imageUrl.length);
      }, 1500);
      return () => clearInterval(interval);
    } else {
      setCurrentSlide(0);
    }
  }, [isHovered, imageUrl]);

  return (
    <div
      style={{
        position: "relative",
        zIndex: "10",
      }}
    >
      <Rate
        style={{
          fontSize: 16,
        }}
        value={rating}
        allowHalf
        disabled
      />
      <Carousel
        showStatus={false}
        showThumbs={false}
        selectedItem={currentSlide}
        infiniteLoop={true}
        emulateTouch
        showArrows={false}
      >
        {renderImages()}
      </Carousel>
      {name && (
        <Flex vertical className="carousal-title" align="flex-start">
          <Title level={4}>{name}</Title>
          <Flex gap={4} align="center">
            <MobileOutlined />
            {contact.split("/").map((item: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 && "/"}
                  <Link to={`tel:+91${item}`}>+91 {item}</Link>
                </React.Fragment>
              );
            })}
            {/* <a href={`tel:${contact}`}>+91 {contact}</a> */}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default ImagesCarousel;
