import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { locationState } from './locationState';

interface LocationContextType {
  userLocation: any;
  setUserLocation: (location:any) => void;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userLocation, setUserLocation] = useRecoilState(locationState);

  return (
    <LocationContext.Provider value={{ userLocation, setUserLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useUserLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('userLocation must be used within a LocationProvider');
  }
  return context;
};
