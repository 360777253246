import "./index.scss";

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { List, Rate, Tag, Typography } from "antd";

import { TurfInfoListProps } from "../../../Interfaces";
import { TurfPricingDetails } from "../TurfPricingDetails";

const openGoogleMaps = (address: string) => {
  const formattedAddress = encodeURIComponent(address);
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`,
    "_blank"
  );
};

const { Text, Title } = Typography;
const TurfInfoList: React.FC<TurfInfoListProps> = ({
  fields,
  data,
  rating,
  handleArea,
  userName 
}) => {
  // const renderLinkField = (label: string, key: string) => {
  //   return (
  //     <>
  //       <div className="list-item-info">
  //         <b>{`${label} :`} </b>
  //         <span>
  //           {data[key].split("/").map((item: string, index: number) => {
  //             return (
  //               <NavLink key={index} to={`tel:${item}`}>
  //                 {item}
  //               </NavLink>
  //             );
  //           })}
  //         </span>
  //       </div>
  //     </>
  //   );
  // };

  // const renderStringAddressField = (label: string, key: string) => (
  //   <>
  //     <div className="list-item-info">
  //       <b>{`${label} `} </b>
  //       <span className="address">{data[key]}</span>
  //     </div>
  //   </>
  // );

  const renderObjectAreaPriceField: React.FC<any> = (key: string) => {
    const keys = data[key];
    const firstSplash = data[key][0];
    return (
      <>
        {/* <div className="list-item-info">
          <tr>
            <tr>
              <th>Area</th>
            </tr>
            <tr>
              <th>Weekday</th>
            </tr>
            <tr>
              <th>Weekend</th>
            </tr>
          </tr>

          {keys.map(
            ({ area, price }: { area: any; price: any }, index: number) => {
              return (
                <tr key={index} className="area-price">
                  <tr>
                    <Text> {area} sqft</Text>
                  </tr>
                  <tr>
                    <td> {price.weekday} Rs. </td>
                  </tr>
                  <tr>
                    <td> {price.weekend} Rs. </td>
                  </tr>
                </tr>
              );
            }
          )}
        </div> */}
      </>
    );
  };

  const renderAreaOptions = (label: string, key: string) => {
    const keys = Object.keys(data[key]);
    return (
      <div className="area-options">
        {keys.map((obj, i) => (
          <Tag bordered={false}>{obj}</Tag>
        ))}
      </div>
    );
  };

  const renderFloatField = (label: string, key: string) => (
    <>
      <div className="list-item-info">
        <b>{`${label} :`} </b>
        <span>
          <NavLink to="#" onClick={() => openGoogleMaps(data['address'])}>
            <img src="/map_logo.png" alt="Map Logo" height={20} />
          </NavLink>
        </span>
      </div>
    </>
  );

  const renderDefaultField = (label: string, key: string) => {
   return (
    <div className="list-item-info">
      <b>{`${label} :`}</b>
      <span>{data[key]}</span>
    </div>
  )};
  // const renderObjectAvailabilityDataField = (label: string, key: string) => {
  //   let value = "";
  //   if (data[key]["openingTime"] !== "" && data[key]["closingTime"] !== "") {
  //     value = `Open at ${data[key]["openingTime"]} and Closed at ${data[key]["closingTime"]}`;
  //   } else if (data[key]["isAvailable24Hours"]) {
  //     value = "24 Hours";
  //   }
  //   return (
  //     <>
  //       <div className="list-item-info">
  //         <b>{`${label} :`} </b>
  //         <span>
  //           {value}
  //           <br />
  //         </span>
  //       </div>
  //     </>
  //   );
  // };

  // const renderFloatField = (label: string, key: string) => (
  //   <>
  //     <div className="list-item-info">
  //       <b>{`${label} :`} </b>
  //       <span>
  //         <NavLink to="#" onClick={() => openGoogleMaps(data["address"])}>
  //           <img src="/map_logo.png" alt="Map Logo" height={20} />
  //         </NavLink>
  //       </span>
  //     </div>
  //   </>
  // );

  // const renderDefaultField = (label: string, key: string) => (
  //   <div className="list-item-info">
  //     <b>{`${label} :`}</b>
  //     <span>{data[key]}</span>
  //   </div>
  // );

  // Function to select the appropriate rendering function based on field type
  // const renderField = (label: string, key: string, type?: string) => {
  //   if (type === "link") {
  //     // return renderLinkField(label, key);
  //     return;
  //   } else if (type === "string" && key === "address") {
  //     return renderStringAddressField(label, key);
  //   } else if (type === "object" && key === "areaPrice") {
  //     return renderObjectAreaPriceField(label, key);
  //   } else if (type === "object" && key === "availabilityData") {
  //     return renderObjectAvailabilityDataField(label, key);
  //   } else if (type === "float") {
  //     return renderFloatField(label, key);
  //   } else {
  //     return renderDefaultField(label, key);
  //   }
  // };


  return (
    <>
      <div>
        {/* <List className="list-box">
        {fields?.map((field, index) => (
          <List.Item key={index} className="list-item">
            {renderField(field.label, field.key, field.type)}
          </List.Item>
        ))}
      </List> */}
        {/* {renderObjectAreaPriceField("areaPrice")} */}
        <TurfPricingDetails data={data} handleArea={handleArea} dataKey="areaPrice" turfName={userName}/>
      </div>
    </>
  );
};

export default TurfInfoList;
