import "./index.scss";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Card, Rate, Typography } from "antd";
import CarouselModel from "../../../../components/ImagesCarousel";
import TurfInfoList from "../TurfInfoList";
import { showBookNow } from "../../../../utils/showFeatures";
import { Tooltip } from "antd";

const Tile = ({
  name,
  userName,
  imageUrl,
  fields,
  data,
  data: { starRating, partnership, areaPrice },
  city,
}: any) => {
  const req_fields = fields.filter((field: { key: string }) => {
    const contact = partnership ? "actualContact" : "displayContact";
    return (
      field.key !== "availabilityData" &&
      field.key !== "isRainProtected" &&
      field.key !== "location" &&
      field.key !== "address" &&
      field.key !== "hasCafe" &&
      field.key !== contact
    );
  });

  const { Text, Title } = Typography;

  const generateUrl = (userName: string) => {
    const protocol = process.env.NODE_ENV === "development" ? "http" : "https";

    let subdomain = userName;
    const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
    let baseURL = `${protocol}://`;
    if (subdomain) {
      baseURL += `${subdomain}.`;
    }
    baseURL += domain;
    return baseURL;
  };

  const handleCardClick = (userName: string, e: MouseEvent) => {
    e.stopPropagation();
    let baseURL = generateUrl(userName);
    if (areaPrice.length >= 1) {
      const area = areaPrice[0].area;
      const price = areaPrice[0].price.weekday.regularHours;
      window.location.href = `${baseURL}?area=${area}&price=${price}`;
    }
  };

  const handleBookNow = (
    e: MouseEvent<HTMLElement>,
    selectedArea: string,
    price: string
  ) => {
    e.stopPropagation();
    let baseURL = generateUrl(userName);
    window.location.href = `${baseURL}/booking?area=${selectedArea}&price=${price}`;
  };

  useEffect(() => {
    setShowPackages(false); // Reset showPackages state when data changes
  }, [data]);

  const rating = starRating ? parseFloat(starRating) : 0;
  const [isHovered, setIsHovered] = useState(false);
  const [selectedArea, setSelectedArea] = useState(areaPrice[0].area);
  const [selectedPrice, setSelectedPrice] = useState(
    areaPrice[0].price.weekday.regularHours
  );
  const [showPackages, setShowPackages] = useState(false);

  const cardRef = useRef(null) as any;
  const hoverRef = useRef(null) as any;
  const turfData = { ...data, name: userName };

  const handleArea = (e: any) => {
    setSelectedArea(e.area);
    setSelectedPrice(e.price.weekday.regularHours);
  };

  const handleShowPackages = (e: MouseEvent) => {
    e.stopPropagation();
    setShowPackages(!showPackages);
  };

  const handleTurfCardClick = (index: number) => {
    const selectedPackage = areaPrice[index];
    setSelectedArea(selectedPackage.area);
    setSelectedPrice(selectedPackage.price.weekday.regularHours);
    let baseURL = generateUrl(userName);
    window.location.href = `${baseURL}?area=${selectedPackage.area}&price=${selectedPackage.price.weekday.regularHours}`;
  };

  const showPackagesButton = data.areaPrice.length > 1;
  const showBookNowButton = showBookNow(city) && !showPackagesButton;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        hoverRef.current.style.top = "unset";
        hoverRef.current.style.left = "unset";
        hoverRef.current.style.opacity = 0;
      }}
      onMouseMove={(e) => {
        const positonObject = cardRef.current.getBoundingClientRect();
        const y = positonObject.y;
        const x = positonObject.x;

        hoverRef.current.style.top = e.clientY - y + "px";
        hoverRef.current.style.left = e.clientX - x + "px";
        hoverRef.current.style.opacity = 1;
      }}
      className={"card-container"}
      ref={cardRef}
      onClick={(e) => {
        handleCardClick(userName, e);
      }}
    >
      <div className="hover" ref={hoverRef}></div>
      {partnership && (
        <Tooltip title="Book our partnered turf to unlock exclusive offers! Enjoy special discounts and benefits just for you.">
          <div className="partnership-flag">Partnered</div>
        </Tooltip>
      )}

      <CarouselModel
        name={name}
        imageUrl={imageUrl}
        isHovered={isHovered}
        imgHeight={`220em`}
        turf={turfData}
        contact={
          turfData?.partnership
            ? turfData?.displayContact
            : turfData?.actualContact
        }
        starRating={starRating}
      />
      {!showPackages && (
        <>
          <TurfInfoList
            name={name}
            fields={req_fields}
            data={data}
            rating={rating}
            handleArea={handleArea}
            userName={userName}
          />
        </>
      )}

      {showPackagesButton && showPackages && (
        <div className="packages-container">
          {areaPrice.map((el: any, i: any) => (
            <div
              key={i}
              onClick={(e) => {
                e.stopPropagation(); // Prevent redirection when package is clicked
                handleTurfCardClick(i);
              }}
            >
              <Card bordered>
                <Title level={5}>Pitch available - {el.area} sq.ft</Title>
                <Title level={5}>L*B*H - {el.pitchDimensions?.length}*{el.pitchDimensions?.width}*{el.pitchDimensions?.height} ft</Title>
                <Title level={5}>
                  Starting @ {el.price.weekday?.regularHours}/-
                </Title>
                <div className="area-container">
                  {showBookNow(city) && (
                    <Button
                      block={true}
                      type="primary"
                      // size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleBookNow(
                          e,
                          el.area,
                          el.price.weekday?.regularHours
                        );
                      }}
                    >
                      Check Availability
                    </Button>
                  )}
                </div>
              </Card>
            </div>
          ))}
        </div>
      )}

      {showBookNowButton && (
        <Button
          className="book-now-btn"
          block={true}
          type="primary"
          size="large"
          onClick={(e) => handleBookNow(e, selectedArea, selectedPrice)}
        >
          Check Availability
        </Button>
      )}

      {showPackagesButton &&
        (!showPackages ? (
          <Button
            block
            type="primary"
            size="large"
            onClick={handleShowPackages}
          >
            Show Packages ({areaPrice.length})
          </Button>
        ) : (
          <Button
            type="link"
            onClick={handleShowPackages}
            style={{
              display: "block",
              padding: 0,
              color: "#1890ff",
              textAlign: "center",
              fontSize: "1.3em",
            }}
            className="back-to-overview"
          >
            <a className="back-to-overview-link"> &lt; back to overview </a>
          </Button>
        ))}
    </div>
  );
};

export default Tile;

// import "./index.scss";

// import React, { MouseEvent, useEffect, useRef, useState } from "react";
// import { Button, Carousel, Card, Rate } from "antd";

// import CarouselModel from "../../../../components/ImagesCarousel";
// import TurfInfoList from "../TurfInfoList";
// import { hideBookNow } from "../../../../utils/hideBookNow";

// const Tile = ({ name, userName, imageUrl, fields, data, data: { starRating, partnership, areaPrice }, city }: any) => {
//   const req_fields = fields.filter(
//     (field: { key: string }) => {
//       const contact = partnership ? "actualContact" : "displayContact"
//       return field.key !== "availabilityData" &&
//         field.key !== "isRainProtected" &&
//         field.key !== "location" &&
//         field.key !== "address" &&
//         field.key !== "hasCafe" &&
//         field.key !== contact
//     }
//   );

//   const generateUrl = (userName: string) => {
//     const protocol = process.env.NODE_ENV === "development" ? "http" : "https";

//     // Determine the subdomain
//     let subdomain = userName;

//     // Get the domain from environment variables
//     const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";

//     // Construct the base URL
//     let baseURL = `${protocol}://`;

//     // Append the subdomain if it exists
//     if (subdomain) {
//       baseURL += `${subdomain}.`;
//     }

//     // Append the domain
//     baseURL += domain;
//     return baseURL;
//   };

//   const handleCardClick = (userName: string) => {
//     // Determine the protocol
//     let baseURL = generateUrl(userName);
//     // Navigate to the subdomain route with the city, replacing the current URL
//     window.location.href = `${baseURL}`;
//   };

//   const handleBookNow = (e: MouseEvent<HTMLButtonElement>) => {
//     e.stopPropagation();
//     let baseURL = generateUrl(userName);
//     const selectedArea = area ? `?area=${area}` : "";
//     window.location.href = `${baseURL}/booking${selectedArea}`;
//   };

//   const rating = starRating ? parseFloat(starRating) : 0;
//   const [isHovered, setIsHovered] = useState(false);
//   const [area, setArea] = useState(areaPrice[0].area);
//   const [showPackages, setShowPackages] = useState(false);

//   const cardRef = useRef(null) as any;
//   const hoverRef = useRef(null) as any;
//   const turfData = { ...data, name: userName };

//   const handleArea = (e: any) => {
//     setArea(e.area);
//   };

//   const showPackagesButton = data.areaPrice.length > 1;
//   const showBookNowButton = !showPackagesButton;

//   return (
//     <div
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => {
//         setIsHovered(false);
//         hoverRef.current.style.top = "unset";
//         hoverRef.current.style.left = "unset";
//         hoverRef.current.style.opacity = 0;
//       }}
//       onMouseMove={(e) => {
//         const positonObject = cardRef.current.getBoundingClientRect();
//         const y = positonObject.y;
//         const x = positonObject.x;

//         hoverRef.current.style.top = e.clientY - y + "px";
//         hoverRef.current.style.left = e.clientX - x + "px";
//         hoverRef.current.style.opacity = 1;
//       }}
//       className={"card-container"}
//       ref={cardRef}
//       onClick={() => {
//         handleCardClick(userName);
//       }}
//     >
//       <div className="hover" ref={hoverRef}></div>
//       <CarouselModel
//         name={name}
//         imageUrl={imageUrl}
//         isHovered={isHovered}
//         imgHeight={`220em`}
//         turf={turfData}
//         contact={turfData?.partnership ? turfData?.displayContact : turfData?.actualContact}
//         starRating={starRating}
//       />
//       <TurfInfoList
//         name={name}
//         fields={req_fields}
//         data={data}
//         rating={rating}
//         handleArea={handleArea}
//       />
//       {showPackagesButton && (
//         <Button
//         block={true}
//         type="primary"
//         size="large"
//         onClick={(e) => {
//           e.stopPropagation();
//           setShowPackages(!showPackages);
//         }}
//         >
//           Show Packages
//         </Button>
//       )}

//       {showBookNowButton && (
//         <Button
//           className="book-now-btn"
//           block={true}
//           type="primary"
//           size="large"
//           onClick={handleBookNow}
//         >
//           Check Availability
//         </Button>
//       )}
//     </div>
//   );
// };

// export default Tile;
