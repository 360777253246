import React, { useEffect } from 'react';
import {  useRecoilState } from 'recoil';
import { useDefaultSortKey } from '../hooks/useDefaultSortKey';
import { filtersState } from '../../pages/HomePage/TileContainer/filters/filtersState';

const FiltersInitializer: React.FC = () => {
    const [filters, setFilters] = useRecoilState(filtersState);

  const defaultSortKey = useDefaultSortKey();

  useEffect(() => {
    // Only set the sort key to default if it hasn't been set yet
    if (!filters.sortBy) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: defaultSortKey,
      }));
    }
  }, [defaultSortKey, filters.sortBy, setFilters]);

  return null; // This component does not render anything
};

export default FiltersInitializer;
