import "../../index.scss";
import "./index.scss";
import constants from "../../utils/constants";


import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import { Divider, Layout, Typography } from "antd";
import { LoginOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { BsInstagram, BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { SiGooglemybusiness } from "react-icons/si";
import DisclaimerModal from "../Disclaimer";

const { Footer } = Layout;
const hostUrl = process.env.REACT_APP_HOST_URL;

function FooterComponent() {
  // const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const { Text, Title } = Typography;
  interface SocialLink {
    link: string;
    className: string;
    icon: ReactNode;
  }

  const config: SocialLink[] = [
    // { link: "https://www.facebook.com/", className: "facebook", icon: <BsFacebook /> },
    {
      link: "https://g.co/kgs/jAcSa66/",
      className: "googleBusiness",
      icon: <SiGooglemybusiness />,
    },
    // { link: "https://www.twitter.com/", className: "twitter", icon: <BsTwitter /> },
    {
      link: "https://www.instagram.com/turfbooking.in",
      className: "instagram",
      icon: <BsInstagram />,
    },
    {
      link: "https://www.linkedin.com/in/turf-booking-platform-016622298",
      className: "linkedin",
      icon: <BsLinkedin />,
    },
  ];

  const handlePrivacyPolicyClick = () => {
    const privacyPolicyUrl = `${hostUrl}/privacy-policy`;
    window.open(privacyPolicyUrl, "_blank");
  };

  const handleAboutUsClick = () => {
    const AboutUsUrl = `${hostUrl}/about-us`;
    window.open(AboutUsUrl, "_blank");
  };

  // const handleDisclaimerClick = () => {
  //   setShowDisclaimerModal(true);
  // };

  const handleDisclaimerClick = () => {
    const DisclaimerUrl = `${hostUrl}/terms`;
    window.open(DisclaimerUrl, "_blank");
  };
  return (
    <Footer className="footer">
      <div className="section">
        <Text className="company-heading">Turf Booking</Text>
        <Text className="description">
          Embark on a sporting journey across cities with our diverse turf
          selection. Find your ideal playing ground and book your game today!
        </Text>
        <div className="section-child">
          <Title level={4} style={{ margin: 0 }}>
            Contact us
          </Title>
          <div
            style={{
              display: "flex",
              gap: "0.15rem",
              alignItems: "center",
            }}
          >
            <Text strong>
              <a href={`tel:+91${constants.BUISNESS_NUMBER}`}>+91 {constants.BUISNESS_NUMBER}</a>
            </Text>
            <Divider type="vertical" />
            <Text strong>
              <a href="mailto:info@turfbooking.in">info@turfbooking.in</a>
            </Text>
          </div>
        </div>
      </div>
      <div className="section">
        <Text className="company-heading">Important links</Text>
        <div className="pages">
          <Title
            level={5}
            onClick={handleAboutUsClick}
            style={{ cursor: "pointer" }}
          >
            About <LoginOutlined />
          </Title>
          <Divider type="vertical" />
          <Title
            level={5}
            onClick={handlePrivacyPolicyClick}
            style={{ cursor: "pointer" }}
          >
            Privacy Policy <LoginOutlined />
          </Title>
          <Divider type="vertical" />
          <Title
            level={5}
            onClick={handleDisclaimerClick}
            style={{ cursor: "pointer" }}
          >
            Terms & Conditions <LoginOutlined />
          </Title>
        </div>
        <ul className="footer-social-container">
          {config.map(
            ({ link, className, icon }: SocialLink, index: number) => {
              return (
                <li key={index} className="social-item">
                  <NavLink to={link} target="_blank" className={className}>
                    {icon}
                  </NavLink>
                </li>
              );
            }
          )}
        </ul>
      </div>
      {/* {showDisclaimerModal && (
        <DisclaimerModal onClose={() => setShowDisclaimerModal(false)} />
      )} */}
      <Divider orientation="center">
        <Text>Turf Booking &copy; {new Date().getFullYear()}</Text>
      </Divider>
    </Footer>
  );
}

export default FooterComponent;
