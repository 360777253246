import React, { useState, useEffect } from "react";
import { TurfPricingDetailsProps } from "../../../Interfaces";
import { Button, Typography, Card, Divider } from "antd";
import "./index.scss";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

export const TurfPricingDetails: React.FC<TurfPricingDetailsProps> = ({
  data,
  dataKey,
  handleArea,
  turfName,
}) => {
  const [selectedArea, setSelectedArea] = useState(data[dataKey][0]);
  const [hoveredPackage, setHoveredPackage] = useState<number | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const generateUrl = (turfName: string) => {
    const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
    const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
    return `${protocol}://${turfName ? `${turfName}.` : ""}${domain}`;
  };

  const handleBookNow = (packageIndex: number, area: string, price: string) => {
    if (turfName) {
      const baseURL = generateUrl(turfName);
      window.location.href = `${baseURL}/booking?area=${area}&price=${price}`;
    }
  };

  useEffect(() => {
    setSelectedArea(data[dataKey][0]);
  }, [dataKey, data]);

  const hasMultiplePitches = data[dataKey].length > 1;

  const highestPitch = data[dataKey].reduce((max: any, pitch: any) =>{ 
    const maxHeight = max?.pitchDimensions?.height;
    const pitchHeight = pitch?.pitchDimensions?.height;
      return maxHeight && pitchHeight && 
      parseFloat(maxHeight) > parseFloat(pitchHeight) ? max : pitch;},
    data[dataKey][0]
  );

  const lowestPricePitch = data[dataKey].reduce((min: any, pitch: any) => {
    const minPrice = min.price?.weekday?.regularHours;
    const pitchPrice = pitch.price?.weekday?.regularHours;
    return minPrice &&
      pitchPrice &&
      parseFloat(pitchPrice) < parseFloat(minPrice)
      ? pitch
      : min;
  }, data[dataKey][0]);

  const handleTurfCardClick = (index: number) => {
    const selectedPackage = data[dataKey][index];
    setSelectedPackage(selectedPackage);
    if (handleArea) {
      handleArea(selectedPackage);
    }
  };
  const [showFullAddress, setShowFullAddress] = useState(false);

  return (
    <div className="turf-data">
      {hasMultiplePitches ? (
        <>
   <div style={{ display: 'flex', alignItems: 'center' }}>
  <Text strong style={{ marginRight: 4 }}>
    {showFullAddress ? data.address : `${data.address.substring(0, 28)}...`}
  </Text>
  {!showFullAddress ? (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation(); // Prevent redirection
        setShowFullAddress(true); // Show full address
      }}
      style={{ padding: 0, lineHeight: 1 }} // Keep the button compact
    >
      See More
    </Button>
  ) : (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation(); // Prevent redirection
        setShowFullAddress(false); // Hide full address
      }}
      style={{ padding: 0, lineHeight: 1 }} // Keep the button compact
    >
      See Less
    </Button>
  )}
</div>


          <Text strong>Pitch available - {highestPitch.area} sq.ft</Text>
          {highestPitch.pitchDimensions && (
            <>
              <Text strong>
                 Dimensions: {highestPitch.pitchDimensions?.length}  L x {highestPitch.pitchDimensions?.width}  W x  {highestPitch.pitchDimensions?.height}  H (in ft.)
              </Text> 
             </> 
          )}
          <Title level={5}>
            Starting @ {lowestPricePitch.price.weekday?.regularHours}/-
          </Title>
          {isMobile && (
            <div>
              {data[data.partnership ? "displayContact" : "actualContact"]
                .split("/")
                .map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    {index > 0 && "/"}
                    <Link to={`tel:+91${item}`}>+91 {item}</Link>
                  </React.Fragment>
                ))}
            </div>
          )}
        </>
      ) : (
        <div className="single-pitch-details">
 <div style={{ display: 'flex', alignItems: 'center' }}>
  <Text strong style={{ marginRight: 4 }}>
    {showFullAddress ? data.address : `${data.address.substring(0, 28)}...`}
  </Text>
  {!showFullAddress ? (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation(); // Prevent redirection
        setShowFullAddress(true); // Show full address
      }}
      style={{ padding: 0, lineHeight: 1 }} // Keep the button compact
    >
      See More
    </Button>
  ) : (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation(); // Prevent redirection
        setShowFullAddress(false); // Hide full address
      }}
      style={{ padding: 0, lineHeight: 1 }} // Keep the button compact
    >
      See Less
    </Button>
  )}
</div>

          <Text strong>Pitch available - {selectedArea.area} sq.ft</Text>

          {selectedArea.pitchDimensions && (
            <>
              <br />
              <Text strong>
                Dimensions: {selectedArea.pitchDimensions?.length}  L x {selectedArea.pitchDimensions?.width}  W x {selectedArea.pitchDimensions?.height}  H (in ft.)
              </Text>
            </>
          )}

          <Title level={5}>
            Starting @ {selectedArea.price.weekday?.regularHours}/-
          </Title>
          {isMobile && (
            <div>
              {data[data.partnership ? "displayContact" : "actualContact"]
                .split("/")
                .map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    {index > 0 && "/"}
                    <Link to={`tel:+91${item}`}>+91 {item}</Link>
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// import React, { useState, useEffect } from "react";
// import { TurfPricingDetailsProps } from "../../../Interfaces";
// import { Button, Typography, Card, Divider } from "antd";
// import "./index.scss";
// import { useMediaQuery } from "react-responsive";
// import { Link } from "react-router-dom";

// const { Text, Title } = Typography;

// export const TurfPricingDetails: React.FC<TurfPricingDetailsProps> = ({
//   data,
//   dataKey,
//   handleArea,
//   turfName,
// }) => {
//   const [selectedArea, setSelectedArea] = useState(data[dataKey][0]);
//   const [showPackages, setShowPackages] = useState(false);
//   const [hoveredPackage, setHoveredPackage] = useState<number | null>(null);
//   const [selectedPackage, setSelectedPackage] = useState<any>(null);

//   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

//   const generateUrl = (turfName: string) => {
//     const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
//     const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
//     return `${protocol}://${turfName ? `${turfName}.` : ""}${domain}`;
//   };

//   const handleBookNow = (packageIndex: number, area: string, price: string) => {
//     if (turfName) {
//       const baseURL = generateUrl(turfName);
//       window.location.href = `${baseURL}/booking?area=${area}&price=${price}`;
//     }
//   };

//   useEffect(() => {
//     setSelectedArea(data[dataKey][0]);
//   }, [dataKey, data]);

//   const toggleShowPackages = (e: React.MouseEvent) => {
//     e.stopPropagation();
//     setShowPackages(!showPackages);
//   };

//   const hasMultiplePitches = data[dataKey].length > 1;

//   const highestPitch = data[dataKey].reduce((max: any, pitch: any) =>
//     parseFloat(pitch.area) > parseFloat(max.area) ? pitch : max,
//     data[dataKey][0]
//   );

//   const lowestPricePitch = data[dataKey].reduce((min: any, pitch: any) => {
//     const minPrice = min.price?.weekday?.regularHours;
//     const pitchPrice = pitch.price?.weekday?.regularHours;
//     return minPrice && pitchPrice && parseFloat(pitchPrice) < parseFloat(minPrice) ? pitch : min;
//   }, data[dataKey][0]);

//   const handleTurfCardClick = (index: number) => {
//     const selectedPackage = data[dataKey][index];
//     setSelectedPackage(selectedPackage);
//     console.log("Selected Package TURF:", selectedPackage);
//     if (handleArea) {
//       console.log("Calling handleArea with:", selectedPackage);
//       handleArea(selectedPackage);
//     }
//   };

//   return (
//     <div className="turf-data">
//       {hasMultiplePitches ? (
//         <>
//           {!showPackages ? (
//             <>
//               <Text strong>Pitch available - {highestPitch.area} sq.ft</Text>
//               <Title level={5}>
//                 Starting @ {lowestPricePitch.price.weekday?.regularHours}/-
//               </Title>
//       {isMobile && (
//         <div>
//           {data[data.partnership ? "displayContact" : "actualContact"]
//             .split("/")
//             .map((item: any, index: any) => (
//               <React.Fragment key={index}>
//                 {index > 0 && "/"}
//                 <Link to={`tel:+91${item}`}>+91 {item}</Link>
//               </React.Fragment>
//             ))}
//         </div>
//       )}
//             </>
//           ) : (
//             <div className="packages-container">
//               {data[dataKey].map((el: any, i: any) => (
//                 <div
//                   key={i}
//                   onMouseEnter={() => setHoveredPackage(i)}
//                   onMouseLeave={() => setHoveredPackage(null)}
//                   className={hoveredPackage === i ? 'hovered-package' : ''}
//                   onClick={() => handleTurfCardClick(i)}
//                 >
//                   <Card bordered>
//                     <Title level={5}>Pitch available - {el.area} sq.ft</Title>
//                     <div className="area-container">
//                       <Title level={5}>Starting @ {el.price.weekday?.regularHours}/-</Title>
//                       <Button
//                         type="primary"
//                         block={isMobile}
//                         onClick={(e) => {
//                           e.preventDefault();
//                           e.stopPropagation();
//                           handleBookNow(i, el.area, el.price.weekday?.regularHours);
//                         }}
//                       >
//                         Check Availability
//                       </Button>
//                     </div>
//                   </Card>
//                   {i < data[dataKey].length - 1 && <Divider style={{ backgroundColor: '#333' }} />}
//                 </div>
//               ))}
//             </div>
//           )}
//           {!showPackages ? (
//             <Button block type="primary" size="large" onClick={toggleShowPackages} style={{ marginTop: "12px" }}>
//               Show Packages ({data[dataKey].length})
//             </Button>
//           ) : (
//             <Button
//               type="link"
//               onClick={toggleShowPackages}
//               style={{ display: 'block', padding: 0, color: '#1890ff', textAlign: 'center', fontSize: '1.3em' }}
//               className="back-to-overview"
//             >
//               <Link to="#" className="back-to-overview-link"> &lt; back to overview </Link>
//             </Button>
//           )}
//         </>
//       ) : (
//         <div className="single-pitch-details">
//           <Text strong>Pitch available - {selectedArea.area} sq.ft</Text>
//           <Title level={5}>
//             Starting @ {selectedArea.price.weekday?.regularHours}/-
//           </Title>
//           {isMobile && (
//             <div>
//               {data[data.partnership ? "displayContact" : "actualContact"]
//                 .split("/")
//                 .map((item: any, index: any) => (
//                   <React.Fragment key={index}>
//                     {index > 0 && "/"}
//                     <Link to={`tel:+91${item}`}>+91 {item}</Link>
//                   </React.Fragment>
//                 ))}
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// import React, { useState, useEffect } from "react";
// import { TurfPricingDetailsProps } from "../../../Interfaces";
// import { Button, Typography, Card, Divider } from "antd";
// import "./index.scss";
// import { useMediaQuery } from "react-responsive";
// import { Link } from "react-router-dom";

// const { Text, Title } = Typography;

// export const TurfPricingDetails: React.FC<TurfPricingDetailsProps> = ({
//   data,
//   dataKey,
//   handleArea,
//   turfName,
// }) => {
//   const [selectedArea, setSelectedArea] = useState(data[dataKey][0]);
//   const [showPackages, setShowPackages] = useState(false);
//   const [hoveredPackage, setHoveredPackage] = useState<number | null>(null);
//   const [selectedPackage, setSelectedPackage] = useState<any>(null);

//   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

//   const generateUrl = (turfName: string) => {
//     const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
//     const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
//     return `${protocol}://${turfName ? `${turfName}.` : ""}${domain}`;
//   };

//   const handleBookNow = (packageIndex: number, area: string, price: string) => {
//     if (turfName) {
//       const baseURL = generateUrl(turfName);
//       window.location.href = `${baseURL}/booking?area=${area}&price=${price}`;
//     }
//   };

//   useEffect(() => {
//     setSelectedArea(data[dataKey][0]);
//   }, [dataKey, data]);

//   const toggleShowPackages = (e: React.MouseEvent) => {
//     e.stopPropagation();
//     setShowPackages(!showPackages);
//   };

//   const hasMultiplePitches = data[dataKey].length > 1;

//   const highestPitch = data[dataKey].reduce((max: any, pitch: any) =>
//     parseFloat(pitch.area) > parseFloat(max.area) ? pitch : max,
//     data[dataKey][0]
//   );

//   const lowestPricePitch = data[dataKey].reduce((min: any, pitch: any) => {
//     const minPrice = min.price?.weekday?.regularHours;
//     const pitchPrice = pitch.price?.weekday?.regularHours;
//     return minPrice && pitchPrice && parseFloat(pitchPrice) < parseFloat(minPrice) ? pitch : min;
//   }, data[dataKey][0]);

//   const handleTurfCardClick = (index: number) => {
//     const selectedPackage = data[dataKey][index];
//     setSelectedPackage(selectedPackage);
//     console.log("Selected Package:", selectedPackage);
//     if (handleArea) {
//       console.log("Calling handleArea with:", selectedPackage);
//       handleArea(selectedPackage);
//     }
//   };

//   return (
//     <div className="turf-data">
//       {hasMultiplePitches ? (
//         <>
//           {!showPackages ? (
//             <>
//               <Text strong>Pitch available - {highestPitch.area} sq.ft</Text>
//               <Title level={5}>
//                 Starting @ {lowestPricePitch.price.weekday?.regularHours}/-
//               </Title>
//       {isMobile && (
//         <div>
//           {data[data.partnership ? "displayContact" : "actualContact"]
//             .split("/")
//             .map((item: any, index: any) => (
//               <React.Fragment key={index}>
//                 {index > 0 && "/"}
//                 <Link to={`tel:+91${item}`}>+91 {item}</Link>
//               </React.Fragment>
//             ))}
//         </div>
//       )}
//             </>
//           ) : (
//             <div className="packages-container">
//               {data[dataKey].map((el: any, i: any) => (
//                 <div
//                   key={i}
//                   onMouseEnter={() => setHoveredPackage(i)}
//                   onMouseLeave={() => setHoveredPackage(null)}
//                   className={hoveredPackage === i ? 'hovered-package' : ''}
//                   onClick={() => handleTurfCardClick(i)}
//                 >
//                   <Card bordered>
//                     <Title level={5}>Pitch available - {el.area} sq.ft</Title>
//                     <div className="area-container">
//                       <Title level={5}>Starting @ {el.price.weekday?.regularHours}/-</Title>
//                       <Button
//                         type="primary"
//                         block={isMobile}
//                         onClick={(e) => {
//                           e.preventDefault();
//                           e.stopPropagation();
//                           handleBookNow(i, el.area, el.price.weekday?.regularHours);
//                         }}
//                       >
//                         Check Availability
//                       </Button>
//                     </div>
//                   </Card>
//                   {i < data[dataKey].length - 1 && <Divider style={{ backgroundColor: '#333' }} />}
//                 </div>
//               ))}
//             </div>
//           )}
//           {!showPackages ? (
//             <Button block type="primary" size="large" onClick={toggleShowPackages} style={{ marginTop: "12px" }}>
//               Show Packages ({data[dataKey].length})
//             </Button>
//           ) : (
//             <Button
//               type="link"
//               onClick={toggleShowPackages}
//               style={{ display: 'block', padding: 0, color: '#1890ff', textAlign: 'center', fontSize: '1.3em' }}
//               className="back-to-overview"
//             >
//               <Link to="#" className="back-to-overview-link"> &lt; back to overview </Link>
//             </Button>
//           )}
//         </>
//       ) : (
//         <div className="single-pitch-details">
//           <Text strong>Pitch available - {selectedArea.area} sq.ft</Text>
//           <Title level={5}>
//             Starting @ {selectedArea.price.weekday?.regularHours}/-
//           </Title>
//           {isMobile && (
//             <div>
//               {data[data.partnership ? "displayContact" : "actualContact"]
//                 .split("/")
//                 .map((item: any, index: any) => (
//                   <React.Fragment key={index}>
//                     {index > 0 && "/"}
//                     <Link to={`tel:+91${item}`}>+91 {item}</Link>
//                   </React.Fragment>
//                 ))}
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };
