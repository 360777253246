import { useState } from "react";
import "./index.scss";

import { Button, Flex, Input, Space } from "antd";
import { useMediaQuery } from "react-responsive";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import LocationAutocomplete from "../../../../components/Location";
import { useUserLocation } from "../../../../components/Location/LocationContext";

const { Search } = Input;

interface SearchBarProps {
  setSearch: (word: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ setSearch }) => {
  const { setUserLocation } = useUserLocation();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm.trim());
  };

  const [isInputVisible, setInputVisible] = useState(false);
  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
    setSearch("");
  };
  const handlePlaceSelected = (place: any) => {

    setUserLocation(place);
  };

  return (
    <>
      {isMobile ? (
        <Flex align="center">
          <Flex vertical gap={4} className="search">
            {!isInputVisible && (
              <Button
                ghost
                icon={<SearchOutlined />}
                onClick={toggleInputVisibility}
                shape="round"
              />
            )}
            {isInputVisible && (
              <Search
                placeholder="Search by turf name"
                allowClear
                size="large"
                className="input-search"
                prefix={<ArrowLeftOutlined onClick={toggleInputVisibility} />}
                suffix={<></>}
                onChange={handleInputChange}
              />
            )}
          </Flex>
        </Flex>
      ) : (
        <Space direction="vertical" size="middle" className="search">
          <Space.Compact size="large">
            <LocationAutocomplete onPlaceSelected={handlePlaceSelected} />
            <Search
              placeholder="Search by turf name"
              allowClear
              size="large"
              className="input-search"
              onChange={handleInputChange}
            />
          </Space.Compact>
        </Space>
      )}
    </>
  );
};

export default SearchBar;
