import { theme, type ThemeConfig } from "antd";
import { purple } from "@ant-design/colors";

const lightTheme: ThemeConfig = {
  token: {
    colorPrimary: purple[4],
  },
  components: {
    Layout: {
      bodyBg: "#fff",
      headerBg: "rgba(57,16,133,0.8)",
      footerBg: "#fff",
      headerPadding: "0 20px",
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Button: {
      borderRadiusLG: 0,
    },
    Card: {
      borderRadiusLG: 0,
    },
    Divider: {
      margin: 4,
      marginLG: 6,
      colorTextHeading: "rgba(0,0,0,0.45)",
      fontSizeLG: 14,
    },
    Select: {
      borderRadius: 0,
      controlHeight: 42,
      fontSize: 14,
    },
    Input: {
      colorBgContainer: "white",
      borderRadiusLG: 0,
    },
    InputNumber: {
      borderRadiusLG: 0,
      colorBgContainer: "rgba(0,0,00,0)",
    },
    Tabs: {
      horizontalMargin: "0 0 32px 0",
    },
    Radio: {
      fontSize: 16,
    },
  },
  algorithm: theme.defaultAlgorithm,
};

export default lightTheme;
