import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Checkbox,
  Radio,
  Drawer,
  Tag,
  Typography,
} from "antd";
import { useMediaQuery } from "react-responsive";
import styles from "./filterSort.module.scss";
import {
  FILTERS_KEYS,
  SORT_KEYS,
  sortOptions,
  SIZE_RANGE,
  DISTANCE_RANGE_MAX,
  FILTERS_ICONS,
  FILTERS_LABELS,
  SORT_ICONS,
} from "./constants/constants";
import {
  SwapOutlined,
  ArrowUpOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getUserLocation from "../../utils/location";
import { useUserLocation } from "../Location/LocationContext";
import { showAlertModal } from "../alertModal";
import SizeRangeSlider from "./sizeRange";
import DistanceSliderRange from "./distanceRange";
const { Option } = Select;

interface FilterSortProps {
  filters: {
    filters?: FILTERS_KEYS[];
    sortBy: SORT_KEYS | undefined;
    sizeRange: number[];
    distanceRange: number;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      filters?: FILTERS_KEYS[];
      sortBy: SORT_KEYS | undefined;
      sizeRange: number[];
      distanceRange: number;
    }>
  >;
}

const FilterSort = ({ filters, setFilters }: FilterSortProps) => {
  // const [sizeRange, setSizeRange] = useState([SIZE_RANGE.MIN, SIZE_RANGE.MAX]); // Initial range values
  const [sortDrawerVisible, setSortDrawerVisible] = useState(false);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

  const [selectedSort, setSelectedSort] = useState<SORT_KEYS | null>(null);
  const { userLocation, setUserLocation } = useUserLocation();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const handleFilterChange = (checkedValues: any) => {
    setFilters((prev) => ({
      ...prev,
      filters: checkedValues,
    }));
  };

  const handleSortChange = (value: SORT_KEYS) => {
    if (
      value === SORT_KEYS.DISTANCE_NEAREST &&
      (!userLocation?.latitude || !userLocation?.longitude)
    ) {
      getUserLocation(setUserLocation, showAlertModal(isMobile));
      return;
    }

    setSelectedSort(value);
    if (value) {
      setFilters((prev) => ({
        ...prev,
        sortBy: value,
      }));
    }
    onCloseDrawer();
  };

  const showFilterDrawer = () => {
    setFilterDrawerVisible(true);
  };

  const onCloseFilterDrawer = () => {
    setFilterDrawerVisible(false);
  };

  const showSortDrawer = () => {
    setSortDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setSortDrawerVisible(false);
  };

  const clearSort = () => {
    setSelectedSort(null);
    setFilters((prev) => ({
      ...prev,
      sortBy: SORT_KEYS.PRICE_LOW_TO_HIGH, // Resetting sort by to default
      // filters: [], // Resetting filters to an empty array
      // sizeRange:[]
    }));
  };

  const clearSizeRange = () => {
    setFilters((prev) => ({
      ...prev,
      sizeRange: [SIZE_RANGE.MIN, SIZE_RANGE.MAX],
    }));
  };

  const clearDistanceRange = () => {
    setFilters((prev) => ({
      ...prev,
      distanceRange: DISTANCE_RANGE_MAX,
    }));
  };

  const handleSizeChange = (value: number[]) => {
    // setSizeRange(value);
    setFilters((prev) => ({
      ...prev,
      sizeRange: value,
    }));
  };

  const handleDistanceChange = (value: number) => {
    setFilters((prev) => ({
      ...prev,
      distanceRange: value,
    }));
  };

  const { Text, Title } = Typography;

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.filterSortContainer}>
            <Title
              level={5}
              onClick={showSortDrawer}
              className={styles.sortFilterText}
            >
              Sort{" "}
              <SwapOutlined
                style={{
                  rotate: "90deg",
                }}
              />
            </Title>
            <Title
              level={5}
              onClick={showFilterDrawer}
              className={styles.sortFilterText}
            >
              Filter <FilterOutlined />
            </Title>
          </div>
          {(selectedSort || filters.filters || filters.sizeRange) && (
            <div className={styles.tag}>
              {selectedSort && (
                <Tag
                  closable
                  onClose={clearSort}
                  className={styles.selectedSortTag}
                >
                  {
                    sortOptions.find((option) => option.key === selectedSort)
                      ?.label
                  }
                </Tag>
              )}
              {filters.filters?.map((filter: FILTERS_KEYS) => (
                <Tag
                  key={filter}
                  closable
                  className={styles.selectedSortTag}
                  onClose={() =>
                    handleFilterChange(
                      filters.filters?.filter((f) => f !== filter)
                    )
                  }
                >
                  <FontAwesomeIcon icon={FILTERS_ICONS[filter]} /> {FILTERS_LABELS[filter]}
                </Tag>
              ))}
              {!!filters.sizeRange?.length &&
                !(
                  filters.sizeRange[0] == SIZE_RANGE.MIN &&
                  filters.sizeRange[1] == SIZE_RANGE.MAX
                ) && (
                  <Tag
                    closable
                    onClose={clearSizeRange}
                    className={styles.selectedSortTag}
                  >
                    <span>
                      {filters.sizeRange[0]} - {filters.sizeRange[1]}
                    </span>
                  </Tag>
                )}
              {!!filters.distanceRange &&
                !(filters.distanceRange == DISTANCE_RANGE_MAX) && (
                  <Tag
                    closable
                    onClose={clearDistanceRange}
                    className={styles.selectedSortTag}
                  >
                    <span>within {filters.distanceRange} km</span>
                  </Tag>
                )}
            </div>
          )}
        </>
      ) : (
        <div>
          <div className={styles.filterSortContainer}>
            <Row gutter={[16, 16]} className={styles.sortRow}>
              <Col span={24}>
                <Title level={3}>Sort By</Title>
                <Select
                  value={filters.sortBy}
                  onChange={handleSortChange}
                  className={styles.sortSelect}
                >
                  {sortOptions.map(({ key, label }) => (
                    <Option value={key} key={key}>
                      {<FontAwesomeIcon icon={SORT_ICONS[key]} style={{ marginRight: '8px' }} />}
                      {label}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.filterOptions}>
              <Col span={24}>
                <Title level={3}>Filters</Title>
                <Checkbox.Group
                  onChange={handleFilterChange}
                  value={filters.filters}
                  className={styles.filterCheckboxes}
                >
                  {Object.entries(FILTERS_KEYS).map(([key, value]) => (
                    <Checkbox key={key} value={key}>
                      <FontAwesomeIcon icon={FILTERS_ICONS[key as FILTERS_KEYS]} /> {FILTERS_LABELS[key as FILTERS_KEYS]}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Col>
            </Row>

            <div style={{ marginTop: "10px" }}>
              <SizeRangeSlider
                sizeRange={filters.sizeRange}
                handleSizeChange={handleSizeChange}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <DistanceSliderRange
                distanceRange={filters.distanceRange}
                handleDistanceChange={handleDistanceChange}
              />
            </div>
          </div>
        </div>
      )}

      <Drawer
        title={<Title level={3}>Sort by</Title>}
        placement="bottom"
        closable={false}
        onClose={onCloseDrawer}
        open={sortDrawerVisible}
        height="60%"
      >
        <Radio.Group
          onChange={(e) => handleSortChange(e.target.value)}
          value={filters.sortBy}
          className={styles.sortOptions}
        >
          {sortOptions.map(({ key, label }) => (
            <Radio key={key} value={key}>
              {<FontAwesomeIcon icon={SORT_ICONS[key]} style={{ marginRight: '8px' }} />}
              <Text strong>{label}</Text>
            </Radio>
          ))}
        </Radio.Group>
      </Drawer>

      <Drawer
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Title level={3}>Filter</Title>
            <span onClick={() => onCloseFilterDrawer()}>
              <CloseCircleOutlined />
            </span>
          </div>
        }
        placement="bottom"
        closable={false}
        onClose={onCloseFilterDrawer}
        open={filterDrawerVisible}
        height="60%"
        className={styles.filterDrawerContainer}
      >
        <Checkbox.Group
          onChange={handleFilterChange}
          value={filters.filters}
          className={styles.filterCheckboxes}
        >
          {Object.entries(FILTERS_KEYS).map(([key, value]) => (
            <Checkbox key={key} value={key}>
              <FontAwesomeIcon icon={FILTERS_ICONS[key as FILTERS_KEYS]} /> {FILTERS_LABELS[key as FILTERS_KEYS]}
            </Checkbox>
          ))}
        </Checkbox.Group>

        {/* Range to select the size */}
        <div style={{ marginTop: "10px" }}>
          <SizeRangeSlider
            sizeRange={filters.sizeRange}
            handleSizeChange={handleSizeChange}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <DistanceSliderRange
            distanceRange={filters.distanceRange}
            handleDistanceChange={handleDistanceChange}
          />
        </div>
      </Drawer>
    </>
  );
};

export default FilterSort;
