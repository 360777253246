// src/pages/HomePage/TileContainer/filters/filtersState.ts
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FILTERS_KEYS, SORT_KEYS, SIZE_RANGE, DISTANCE_RANGE_MAX } from '../../../../components/FilterAndSort/constants/constants';

export interface FiltersState {
  filters?: FILTERS_KEYS[];
  sortBy: SORT_KEYS | undefined;
  sizeRange: number[];
  distanceRange: number;
}

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // Key for the storage
  storage: sessionStorage, // Use session storage instead of local storage
});

export const filtersState = atom<FiltersState>({
  key: 'filtersState',
  default: {
    sortBy: undefined, // Temporary default value
    sizeRange: [SIZE_RANGE.MIN, SIZE_RANGE.MAX],
    distanceRange: DISTANCE_RANGE_MAX,
  },
  effects_UNSTABLE: [persistAtom],
});
