import React, { useState } from "react";
import "./index.scss";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Rate,
  Row,
  Typography,
} from "antd";
import constants from "../../../utils/constants";
import {
  FrownOutlined,
  HomeOutlined,
  MehOutlined,
  SmileOutlined,
} from "@ant-design/icons";

const FeedbackPage: React.FC = () => {
  const { Text, Title } = Typography;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [val, setVal] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(false);

  const scriptFeedbackUrl = process.env.REACT_APP_GOOGLE_APPS_SCRIPT_FEEDBACK_URL;
  const handleHomeRedirect = () => {
    window.location.href = "/";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);

    try {
      //   const response = await fetch(
      //     "https://script.google.com/macros/s/AKfycbzHQ7G15EJh5eoNKQ1sDRZaA5EmVmEebUvhW9Gyyz4uKt5__pFrsHyg9niPJzbVBm3TsA/exec",
      //     {
      //       method: "POST",
      //       mode: "no-cors",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({
      //         rating,
      //         howDidYouFindUs,
      //         improvements,
      //         otherSource,
      //       }),
      //     }
      //   );
      setModalOpen(true);
      setLoading(false);
    } catch (error: any) {
      console.error("Error submitting feedback data:", error.message);
      setLoading(false);
    }
  };

  const customIcons: Record<number, React.ReactNode> = {
  1: "😢",
  2: "😕",
  3: "😐",
  4: "🙂",
  5: "😄",
  };

  const [form] = Form.useForm();

  return (
    <Flex vertical gap={32} align="middle" className="feedback-container">
      <Title>Your Feedback is Important To Us</Title>
      <Form
        form={form}
        onFinish={async (e) => {
          setLoading(true);
          try {
            const response = await fetch(
              `${scriptFeedbackUrl}`,
              {
                method: "POST",
                mode: "no-cors",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(e),
              }
            );
            setModalOpen(true);
            setLoading(false);
          } catch (error: any) {
            console.error("Error submitting feedback data:", error.message);
            setLoading(false);
          }
        }}
        layout="vertical"
      >
        <Form.Item
          name={"rating"}
          label={<Title level={4}>How do you rate your experience?</Title>}
        >
          <Rate
            style={{
              fontSize: "32px",
            }}
            character={({ index = 0 }) => (
              <span className="emoji">{customIcons[index + 1]}</span>
            )}
          />
        </Form.Item>
        <Row gutter={4}>
          <Col span={14}>
            <Form.Item
              name={"howDidYouFindUs"}
              label={<Title level={4}>How did you find us?</Title>}
            >
              <Radio.Group onChange={(e) => setVal(e.target.value)}>
                <Radio value={"WOM"}>Word of mouth</Radio>
                <Radio value={"OS"}>Online search</Radio>
                <Radio value={"SM"}>Social media</Radio>
                <Radio value={"AD"}>Advertisement</Radio>
                <Radio value={"O"}>Others</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={"others"}
              label={<Title level={5}>Others - please state</Title>}
            >
              <Input disabled={!(val === "O")} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={"improvements"}
          label={
            <Title level={4}>
              Any improvement or suggestions for the website?
            </Title>
          }
        >
          <Input.TextArea
            style={{
              resize: "none",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} htmlType="submit" type="primary" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        styles={{
          body: {
            width: "fit-content",
          },
          content: {
            width: "fit-content",
          },
        }}
        closable={false}
        open={modalOpen}
        footer={<></>}
      >
        <Flex vertical align="center" gap={8}>
          <Title level={3}>Thank you for valuable time!</Title>
          <Text
            style={{
              fontSize: "100px",
            }}
          >
            🏏
          </Text>
          <Button
            onClick={handleHomeRedirect}
            type="primary"
            style={{
              fontWeight: 600,
              fontSize: 16,
              height: "fit-content",
              borderRadius: 16,
              padding: "0.5rem 2rem",
            }}
          >
            Home <HomeOutlined />
          </Button>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default FeedbackPage;
