import { useEffect } from "react";
import { notification } from "antd";

interface ToastProps {
  message: string;
  success: boolean;
  onClose?: () => void;
  placement?: "top" | "topLeft" | "topRight" | "bottom" | "bottomLeft" | "bottomRight" | undefined;
}

const Toast: React.FC<ToastProps> = ({
  message,
  success,
  onClose,
  placement,
}) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (message) {
      const obj = {
        message: message,
        onClose: onClose,
        placement: placement,
      };
      if (success) {
        api.success({
          ...obj,
          key: 1,
        });
      } else {
        api.error({
          ...obj,
          key: 2,
        });
      }
    }
  }, [message, success, onClose, placement, api]);

  return contextHolder;
};

export default Toast;
