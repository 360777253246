import React, { Dispatch, useEffect, useState } from "react";
import "./noDataFound.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import constants from "../../utils/constants";
import SelectLocation from "../../components/SelectLocation";
import { useNavigate } from "react-router-dom";

interface NotAvailablePageProps {
  setCity: Dispatch<React.SetStateAction<string>>;
}

const NotAvailablePage = ({ setCity }: NotAvailablePageProps) => {
  const [visible, setVisible] = useState(true);

  const localStorageCity = localStorage.getItem("selectedCity");

  const goBack = () => {
    window.location.href = constants.HOST_URL;
  };

  return (
    <div>
      <Button
        className="back-button"
        onClick={goBack}
        icon={<ArrowLeftOutlined />}
        shape="circle"
        type="primary"
      />
      <div className="not-available-container">
        <h1>Big Things Are Just Around the Corner!</h1>
        <p>
          We're expanding our turf booking service to reach even more locations.
        </p>
        <p>Although we're not in your area just yet, stay tuned for updates!</p>
      </div>
     
     {!localStorageCity && (
        <SelectLocation
          setCity={setCity}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      )}
    </div>
  );
};

export default NotAvailablePage;
