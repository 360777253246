import "./index.scss";

import React from "react";
import { Link, NavLink } from "react-router-dom";

import { List, Rate } from "antd";

import { TurfInfoListProps } from "../../../Interfaces";
import { useMediaQuery } from "react-responsive";
import { TurfPricingDetails } from "../TurfPricingDetails";

const openGoogleMaps = (address: string) => {
  const formattedAddress = encodeURIComponent(address);
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`,
    "_blank"
  );
};

const MobileCardInfo: React.FC<TurfInfoListProps> = ({
  rating,
  fields,
  data,
  name,
  handleArea,
  userName,
}) => {
  const renderLinkField = (label: string, key: string) => {
    return (
      <>
        <div className="list-item-info">
          <b>{`${label} :`} </b>
          <span>
            {data[key].split("/").map((item: string, index: number) => {
              return (
                <Link
                  key={index}
                  title={`${name} | ${data.city} | turfbooking`}
                  to={`tel:${item}`}
                >
                  {item}
                </Link>
              );
            })}
          </span>
        </div>
      </>
    );
  };

  const renderStringAddressField = (label: string, key: string) => (
    <>
      <div className="list-item-info">
        <b>{`${label} `} </b>
        <span className="address">{data[key]}</span>
      </div>
    </>
  );

  const renderObjectAreaPriceField = (label: string, key: string) => {
    const keys = data[key];

    return (
      <div className="object-area-price-field">
        <div className="price-list">
          {keys.map((item: any, index: any) => (
            <div className="price-item" key={index}>
              <div className="area">Area: {item.area} Sqft</div>
              <div className="price">Rs. {item.price.weekday} (Weekday)</div>
              <div className="price">Rs. {item.price.weekend} (Weekend)</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderObjectAvailabilityDataField = (label: string, key: string) => {
    let value = "";
    if (data[key]["openingTime"] !== "" && data[key]["closingTime"] !== "") {
      value = `Open at ${data[key]["openingTime"]} and Closed at ${data[key]["closingTime"]}`;
    } else if (data[key]["isAvailable24Hours"]) {
      value = "24 Hours";
    }
    return (
      <>
        <div className="list-item-info">
          <b>{`${label} :`} </b>
          <span>
            {value}
            <br />
          </span>
        </div>
      </>
    );
  };

  const renderFloatField = (label: string, key: string) => (
    <>
      <div className="list-item-info">
        <b>{`${label} :`} </b>
        <span>
          <NavLink to="#" onClick={() => openGoogleMaps(data["address"])}>
            <img src="/map_logo.png" alt="Map Logo" height={20} />
          </NavLink>
        </span>
      </div>
    </>
  );

  const renderDefaultField = (label: string, key: string) => (
    <div className="list-item-info">
      <b>{`${label} :`}</b>
      <span>{data[key]}</span>
    </div>
  );

  // Function to select the appropriate rendering function based on field type
  // const renderField = (label: string, key: string, type?: string) => {
  //   if (type === "string" && key === "address") {
  //     return renderStringAddressField(label, key);
  //   } else if (type === "object" && key === "areaPrice") {
  //     return renderObjectAreaPriceField(label, key);
  //     // return renderObjectAreaPriceFieldMobile(label, key);
  //   } else if (type === "object" && key === "availabilityData") {
  //     return renderObjectAvailabilityDataField(label, key);
  //   } else if (type === "float") {
  //     return renderFloatField(label, key);
  //   } else if (type === "link") {
  //     return renderLinkField(label, key);
  //   } else {
  //     return renderDefaultField(label, key);
  //   }
  // };
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <TurfPricingDetails data={data} handleArea={handleArea} dataKey="areaPrice" turfName={userName}/>
    </div>
  );
};

export default MobileCardInfo;
