import React from 'react';


interface MapProps {
  latitude: number;
  longitude: number;
}

const MapComponent : React.FC<MapProps>= ({ latitude, longitude }) => {
  const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&output=embed`;

  return (
    <iframe
      title="Google Map"
      src={mapUrl}
      className='map-container'
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default MapComponent;
