// src/pages/HomePage/TileContainer/filters/filtersState.ts
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FILTERS_KEYS, SORT_KEYS, SIZE_RANGE, DISTANCE_RANGE_MAX } from '../FilterAndSort/constants/constants';

export interface LocationState {
    userLocation: any;

}

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // Key for the storage
  storage: sessionStorage, // Use session storage instead of local storage
});

export const locationState = atom<LocationState>({
  key: 'locationState',
  default: {
   userLocation:null
  },
  effects_UNSTABLE: [persistAtom],
});
