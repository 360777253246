import React from "react";
import { Slider, Typography } from "antd";
import { SIZE_RANGE } from "../constants/constants";

interface SizeRangeSliderProps {
  sizeRange: number[];
  handleSizeChange: (value: number[]) => void;
}

const { Title } = Typography;

const SizeRangeSlider: React.FC<SizeRangeSliderProps> = ({
  sizeRange,
  handleSizeChange,
}) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <Title level={3}>Size Range</Title>
      <Slider
        range
        defaultValue={sizeRange}
        min={SIZE_RANGE.MIN}
        step={50}
        max={SIZE_RANGE.MAX}
        onChange={handleSizeChange}
        value={sizeRange}
      />
      <p>
        Selected Range: {sizeRange[0]} - {sizeRange[1]}
      </p>
    </div>
  );
};

export default SizeRangeSlider;
