import "./index.scss";
import "../../index.scss";
import { useLocation } from "react-router-dom";
import { Flex, Layout } from "antd";
import SearchBar from "../../pages/HomePage/TileContainer/Search";
import { turfData } from "../../config/turf.config";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import EscobarLogo from "../../asset/escobar_logo1.png";
import SelectLocation from "../SelectLocation";

const { Header } = Layout;
function HeaderComponent({
  name,
  setCity,
  setSearch,
}: {
  name: string | null;
  setCity: Dispatch<SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [turfDetail, setTurfDetail] = useState<any>([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const location = useLocation(); // Get the current location

  // Conditionally render the SearchBar
  const renderSearchBar = location.pathname === "/";
  const renderSelectCity =
    location.pathname === "/noData" || location.pathname === "/";

  const handleHeaderClick = () => {
    // Determine the protocol
    const protocol = process.env.NODE_ENV === "development" ? "http" : "https";

    // Determine the subdomain
    let subdomain = "";

    if (process.env.NODE_ENV !== "development") {
      // In deployment, use 'www' as the subdomain
      subdomain = "www";
    }

    // Get the domain from environment variables
    const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";

    // Construct the base URL
    let baseURL = `${protocol}://`;

    // Append the subdomain if it exists
    if (subdomain) {
      baseURL += `${subdomain}.`;
    }

    // Append the domain
    baseURL += domain;

    // Navigate to the subdomain route with the city, replacing the current URL
    window.location.href = `${baseURL}`;
  };

  const closeModal = () => {
    setShowLocationModal(false);
  };

  useEffect(() => {
    const currentTurf = turfData.find(turf => turf.userName === name )
    setTurfDetail(currentTurf);
  }, []);
  const imgMeta = `${turfDetail?.name} | turfBooking.in`;

  const handleShowLocation = () => {
    setShowLocationModal(true);
  };

  return (
    <Header className="header-container position-sticky justify-space-between align-item-center">
      <div className="header-content" onClick={handleHeaderClick}>
        {turfDetail?.logo ? (
          <Flex align="center" gap={16}>
            <img
              src={turfDetail?.logo}
              alt={imgMeta}
              title={imgMeta}
              className="turf-logo-image"
              width={50}
              height={50}
            />
            <img
              src={"/LogoText.png"}
              alt={imgMeta}
              title={imgMeta}
              className="logo-image"
            />
          </Flex>
        ) : (
          <img
            src={"/LogoText.png"}
            alt={imgMeta}
            title={imgMeta}
            className="logo-image"
          />
        )}
      </div>
      <div className="search-location">
        {!name && renderSearchBar && <SearchBar setSearch={setSearch} />}
        {!name && renderSelectCity && (
          <div className="location-icon" onClick={handleShowLocation}>
            <img src="/Icon/gps.svg" />
          </div>
        )}

        {showLocationModal && (
          <SelectLocation
            setCity={setCity}
            visible={true}
            onClose={closeModal}
          />
        )}
      </div>
    </Header>
  );
}

export default HeaderComponent;
