import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { EnvironmentOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./location.scss";
import { useUserLocation } from "./LocationContext";
import getUserLocation from "../../utils/location";

interface Place {
  latitude: number;
  longitude: number;
  name: string;
  address: string;
}

interface LocationAutocompleteProps {
  onPlaceSelected: (place: Place | null) => void;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  onPlaceSelected,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const { userLocation, setUserLocation } = useUserLocation();

  useEffect(() => {
    if (autocomplete) {
      autocomplete.setFields(["geometry", "name", "formatted_address"]);
    }
  }, []);

  const handlePlaceSelect = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const newLocation = place.name || place.formatted_address || "";
      setSelectedLocation(newLocation);
      onPlaceSelected({
        latitude: place.geometry?.location?.lat() || 0,
        longitude: place.geometry?.location?.lng() || 0,
        name: place.name || "",
        address: place.formatted_address || "",
      });
    }
  };

  const handleClearLocation = () => {
    setSelectedLocation("");
    setUserLocation(null); // Set the selected place to null
    getUserLocation (setUserLocation)
  };

  return (
    <>
      <Autocomplete
        onLoad={(auto) => setAutocomplete(auto)}
        onPlaceChanged={handlePlaceSelect}
        className="custom-autocomplete"
      >
        <Input
          prefix={<EnvironmentOutlined style={{ color: "#483285" }} />}
          suffix={
            selectedLocation && (
              <CloseCircleOutlined onClick={handleClearLocation} />
            )
          }
          placeholder={userLocation?.name}
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
        />
      </Autocomplete>
    </>
  );
};

export default LocationAutocomplete;
