import { Typography } from "antd";
import React from "react";

interface TurfPrice {
  area: string;
  price: {
    weekday: number;
    weekend: number;
  };
}

interface TurfProps {
  areaPrice: TurfPrice[];
}

const { Title } = Typography;

const OverviewComponent: React.FC<TurfProps> = ({ areaPrice }) => {
  const numTurfsAvailable = areaPrice.length;

  return (
    <div>
      <Title level={5}>
        This turf is in high demand, boasts a beautiful ambience & offers{" "}
        {numTurfsAvailable} turf{numTurfsAvailable > 1 ? "s" : ""}.
      </Title>
      {/* {areaPrice.map((turf, index) => (
        <p key={turf.area}>
          {index > 0 && index === numTurfsAvailable - 1 ? 'and ' : ''}
          {index > 0 && index !== numTurfsAvailable - 1 ? 'the other ' : 'One '}
          Turf is {turf.area} sq. ft and priced at ₹ {turf.price.weekday} (weekday) and ₹ {turf.price.weekend} (weekend).
        </p>
      ))}
      {numTurfsAvailable > 1 && (
        <p>
          You have the option to book any of these turfs individually or the combined area of all turfs.
        </p>
      )} */}
    </div>
  );
};

export default OverviewComponent;
