import React from "react";
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from "react-helmet-async";
type Props = {
  title: string;
  description: string;
  link: string;
};

const HelmetComponent = ({ title, description, link }: Props) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={link} />
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetComponent;
