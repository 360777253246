const getUserLocation = async (
  setUserLocation: Function,
  alertModal?: Function
) => {
  if (navigator.geolocation) {
    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
          });
        }
      );

      const { latitude, longitude } = position.coords;
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const locationName = data.results[0].formatted_address;

        const city = data.results[0].address_components.find((data: any) =>
          data.types.includes("locality")
        ).long_name;

        const localStorageCity = localStorage.getItem("selectedCity");
        if (!localStorageCity) localStorage.setItem("selectedCity", city);
       
        setUserLocation({
          latitude,
          longitude,
          name: locationName,
          city,
        });
      } else {
        console.error("Reverse geocoding failed:", data.status);
      }
    } catch (error) {
      console.error("Error getting user location:", error);
      alertModal && alertModal();
    }
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};

export default getUserLocation;
