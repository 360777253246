export const formatDuration = (duration: any) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    let formattedDuration = "";
    if (hours > 0) {
      formattedDuration += `${hours} hr `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} mins`;
    }
    return formattedDuration.trim();
  };
  