import React from 'react';
import { Typography } from 'antd';
import './index.scss';

const { Text, Title } = Typography;

const DisclaimerComponent = () => {
  return (
    <div className="disclaimer-container">
      <div className="heading">
        <Title>Terms & Conditions</Title>
      </div>

      <div className="section">
        <h2>1. Turf Availability</h2>
        <Text>
          <strong>Information Accuracy:</strong> While we strive to provide accurate and up-to-date information about turf availability, timings, and pricing, we cannot guarantee the accuracy or completeness of the data presented on our platform. Turf availability may be subject to change without prior notice.
        </Text>
        <Text>
          <strong>Third-Party Listings:</strong> Our platform aggregates information about turfs from various sources, including third-party providers. We do not guarantee the availability or quality of the facilities.
        </Text>
      </div>

      <div className="section">
        <h2>2. Booking Terms and Conditions</h2>
        <Text>
          <strong>Reservation Confirmation:</strong> Booking a turf through our platform does not guarantee the availability of the facility at the specified date and time. Turf availability is subject to confirmation by the turf owner or manager.
        </Text>
        <Text>
          <strong>Cancellation Policy:</strong> Each turf may have its own cancellation policy, which will be communicated to you at the time of booking. It is your responsibility to review and adhere to the cancellation terms and conditions.
        </Text>
      </div>

      <div className="section">
        <h2>3. User Responsibility</h2>
        <Text>
          <strong>Accurate Information:</strong> You are responsible for providing accurate and complete information when booking a turf through our platform. Any discrepancies or inaccuracies in the information provided may result in difficulties in confirming your reservation.
        </Text>
        <Text>
          <strong>Compliance with Rules and Regulations:</strong> When using the turfs booked through our platform, you are expected to comply with the rules and regulations set forth by the turf owner or manager. Failure to do so may result in the cancellation of your booking or additional charges.
        </Text>
      </div>

      <div className="section">
        <h2>4. Limitation of Liability</h2>
        <Text>
          <strong>Indirect Damages:</strong> We shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our platform or the turfs booked through our platform.
        </Text>
      </div>

      <div className="section">
        <h2>5. Modification of Terms</h2>
        <Text>
          We reserve the right to modify or update these conditions at any time without prior notice. It is your responsibility to review this page periodically for any changes.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Contact Us</Title>
        <Text>
          If you have any questions or concerns about this conditions or our services, please contact us at <a href="mailto:info@turfbooking.in">info@turfbooking.in</a>.
        </Text>
        <Text>
          By using Turf Booking, you acknowledge and agree to the terms and conditions. Thank you for trusting us.
        </Text>
      </div>
    </div>
  );
};

export default DisclaimerComponent;


// import React, { useState, useEffect } from 'react';
// import './index.scss';

// interface DisclaimerModalProps {
//   onClose: () => void;
// }

// const DisclaimerModal = ({ onClose }: DisclaimerModalProps) => {
//   const [showModal, setShowModal] = useState(true);
//   const [isAgreed, setIsAgreed] = useState(false);

//   const handleCloseModal = () => {
//     setShowModal(false);
//     onClose();
//     localStorage.setItem('disclaimerAccepted', 'true');
//   };

//   useEffect(() => {
//     const disclaimerAccepted = localStorage.getItem('disclaimerAccepted');
//     if (disclaimerAccepted) {
//       setShowModal(true);
//     }
//   }, []);

//   return (
//     <>
//       <div className={`overlay ${showModal ? 'active' : ''}`} />

//       {showModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <h1>Disclaimer</h1>
//             <div className="content">
//               <section>
//                 <h2>1. Turf Availability</h2>
//                 <ul>
//                   <li>
//                     <strong>Information Accuracy:</strong> While we strive to provide accurate and up-to-date information about turf availability, timings, and pricing, we cannot guarantee the accuracy or completeness of the data presented on our platform. Turf availability may be subject to change without prior notice.
//                   </li>
//                   <li>
//                     <strong>Third-Party Listings:</strong> Our platform aggregates information about turfs from various sources, including third-party providers. We do not guarantee the availability or quality of the facilities.
//                   </li>
//                 </ul>
//               </section>

//               <section>
//                 <h2>2. Booking Terms and Conditions</h2>
//                 <ul>
//                   <li>
//                     <strong>Reservation Confirmation:</strong> Booking a turf through our platform does not guarantee the availability of the facility at the specified date and time. Turf availability is subject to confirmation by the turf owner or manager.
//                   </li>
//                   <li>
//                     <strong>Cancellation Policy:</strong> Each turf may have its own cancellation policy, which will be communicated to you at the time of booking. It is your responsibility to review and adhere to the cancellation terms and conditions.
//                   </li>
//                 </ul>
//               </section>

//               <section>
//                 <h2>3. User Responsibility</h2>
//                 <ul>
//                   <li>
//                     <strong>Accurate Information:</strong> You are responsible for providing accurate and complete information when booking a turf through our platform. Any discrepancies or inaccuracies in the information provided may result in difficulties in confirming your reservation.
//                   </li>
//                   <li>
//                     <strong>Compliance with Rules and Regulations:</strong> When using the turfs booked through our platform, you are expected to comply with the rules and regulations set forth by the turf owner or manager. Failure to do so may result in the cancellation of your booking or additional charges.
//                   </li>
//                 </ul>
//               </section>

//               <section>
//                 <h2>4. Limitation of Liability</h2>
//                 <ul>
//                   <li>
//                     <strong>Indirect Damages:</strong> We shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our platform or the turfs booked through our platform.
//                   </li>
//                 </ul>
//               </section>

//               <section>
//                 <h2>5. Modification of Terms</h2>
//                 <ul>
//                   <li>
//                     We reserve the right to modify or update these disclaimers at any time without prior notice. It is your responsibility to review this page periodically for any changes.
//                   </li>
//                 </ul>
//               </section>
//             </div>
//           </div>
//           <div className="bottom-section">
//             <p>
//               By using <b>Turf Booking</b>, you acknowledge and agree to the terms and conditions outlined in this disclaimer. If you have any questions or concerns, please contact us at <a href="mailto:info@turfbooking.in">info@turfbooking.in</a>.
//             </p>
//             <input
//               type="checkbox"
//               id="agreeCheckbox"
//               checked={isAgreed}
//               onChange={() => setIsAgreed(!isAgreed)}
//             />
//             <label htmlFor="agreeCheckbox">I Agree to the Terms and Conditions</label>
//             <button onClick={handleCloseModal} disabled={!isAgreed}>
//               I Agree
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default DisclaimerModal;
