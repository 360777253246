import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ConfigProvider, Layout } from "antd";
import { clarity } from "react-microsoft-clarity";
import "./Loader.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import FooterComponent from "./components/Footer";
import HeaderComponent from "./components/Header";
import TileContainer from "./pages/HomePage/TileContainer";
import SingleTileContainer from "./pages/HomePage/SingleTileContainer";
import Booking from "./pages/HomePage/Booking";
import LandingPopUp from "./components/LandingPopUp";
import SuccessPage from "./pages/SuccessPage";
import FeedbackPage from "./pages/SuccessPage/FeedbackPage";
import PrivacyPolicyComponent from "./components/PrivacyPolicy";
import AboutUsComponent from "./components/AboutUs";
import DisclaimerComponent from "./components/Disclaimer";
import {
  LocationProvider,
  useUserLocation,
} from "./components/Location/LocationContext";
import lightTheme from "./theme/lightTheme";
import constants from "./utils/constants";
import NoDataFound from "./pages/NoDataFound";
import { turfData } from "./config/turf.config";
import { RecoilRoot } from "recoil";

const { Content } = Layout;
function extractNameFromSubdomain() {
  const subdomain = window.location.hostname.split(".")[0]; // Get the subdomain
  // Extract the name from the subdomain (you may need to adjust this logic based on your domain structure)

  const filteredTurf = turfData.find(
    (item) => item.userName?.toLowerCase() === subdomain
  );
  const turfName = filteredTurf?.name || "";
  const turfNumber = filteredTurf?.data.actualContact.split('/')[0];
  const turfCity = filteredTurf?.data.city || "";
  const userName = filteredTurf?.userName || "";

  if (process.env.NODE_ENV === "development" && subdomain === "localhost") {
    return { name: null, turfName: null };
  }
  const Tname = userName !== "www" ? userName : null;
  return { name: Tname, turfName: turfName, turfCity, turfNumber };
}
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [city, setCity] = useState(
    localStorage.getItem("selectedCity") || constants.DEFAULT_CITY
  );
  const { name, turfName, turfCity, turfNumber } = extractNameFromSubdomain();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulating data fetching with a delay
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // If you need to fetch real data, replace the above line with actual data fetching, e.g.:
        // const response = await fetch('your-data-endpoint');
        // const data = await response.json();
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const layoutStyle = {
    minHeight: "100vh",
  };
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_CLARITY_ID
  ) {
    clarity.init(process.env.REACT_APP_CLARITY_ID);
  }
  const [search, setSearch] = useState("");
  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <ClipLoader size={50} color="purple" loading={true} />
          <p>Loading...</p>
        </div>
      ) : (
          <RecoilRoot>
        <LocationProvider>
          <Router>
            <ConfigProvider theme={lightTheme}>
              <Layout style={layoutStyle}>
                <HeaderComponent
                  name={name}
                  setCity={setCity}
                  setSearch={setSearch}
                />
                <Content>
                  <Routes>
                    <Route path="/about-us" element={<AboutUsComponent />} />
                    <Route
                      path="/privacy-policy"
                      element={<PrivacyPolicyComponent />}
                    />
                    <Route path="/terms" element={<DisclaimerComponent />} />
                    {name ? (
                      <Route
                        path="/"
                        element={
                          <SingleTileContainer name={name} city={city} />
                        }
                      />
                    ) : (
                      <Route
                        path={`/`}
                        element={
                          <TileContainer
                            city={city}
                            search={search}
                            setCity={setCity}
                          />
                        }
                      />
                    )}
                    <Route
                      path="/booking"
                     element={<Booking name={turfName} city={turfCity} turfNumber={turfNumber}/>}
                    />
                    <Route
                      path="/success"
                      element={<SuccessPage name={turfName} />}
                    />
                    <Route path="/feedback" element={<FeedbackPage />} />
                    <Route
                      path="/noData"
                      element={<NoDataFound setCity={setCity} />}
                    />
                  </Routes>
                </Content>
                <FooterComponent />
              </Layout>
            </ConfigProvider>
          </Router>
        </LocationProvider>
         </RecoilRoot>
      )}
    </div>
  );
}
export default App;
