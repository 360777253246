import "./index.scss";
import React, { MouseEvent, useState, useEffect } from "react";
import { Button, Col, Rate, Row, Typography, Card } from "antd";
import MobileCardInfo from "../../pages/HomePage/TileContainer/TurfInfoList/MobileCardInfo";
import { showBookNow } from "../../utils/showFeatures";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { LazyLoadImage } from "react-lazy-load-image-component";

const MobileCard = ({ name, userName, imageUrl, fields, data, city }: any) => {
  const [area, setArea] = useState(data.areaPrice[0].area);
  const [price, setPrice] = useState(
    data.areaPrice[0].price.weekday.regularHours
  );
  const [showPackages, setShowPackages] = useState(false);

  useEffect(() => {
    setShowPackages(false);
  }, [data]);

  const { Title } = Typography;

  const req_fields = fields.filter((field: { key: string }) => {
    const contact = data.partnership ? "actualContact" : "displayContact";
    return (
      field.key !== "availabilityData" &&
      field.key !== "isRainProtected" &&
      field.key !== "location" &&
      field.key !== "address" &&
      field.key !== "hasCafe" &&
      field.key !== contact
    );
  });

  const generateUrl = (userName: string) => {
    const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
    let subdomain = userName;
    const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
    let baseURL = `${protocol}://`;
    if (subdomain) {
      baseURL += `${subdomain}.`;
    }
    baseURL += domain;
    return baseURL;
  };

  const handleCardClick = (userName: string, e: MouseEvent) => {
    e.stopPropagation();
    let baseURL = generateUrl(userName);
    if (data.areaPrice.length >= 1) {
      const area = data.areaPrice[0].area;
      const price = data.areaPrice[0].price.weekday.regularHours;
      window.location.href = `${baseURL}?area=${area}&price=${price}`;
    }
  };

  const handleBookNow = (
    e: MouseEvent<HTMLElement>,
    selectedArea: string,
    selectedPrice: string
  ) => {
    e.stopPropagation();
    let baseURL = generateUrl(userName);
    window.location.href = `${baseURL}/booking?area=${selectedArea}&price=${selectedPrice}`;
  };

  const rating = data.starRating ? parseFloat(data.starRating) : 0;
  const imgMeta = ` ${userName} | ${city} | turfbooking`;

  const handleArea = (e: any) => {
    setArea(e.area);
    setPrice(e.price.weekday.regularHours);
    setShowPackages(!showPackages);
  };

  const showPackagesButton = data.areaPrice.length > 1;
  const showBookNowButton = showBookNow(city) && !showPackagesButton;

  const handleShowPackages = (e: MouseEvent) => {
    e.stopPropagation();
    setShowPackages(!showPackages);
  };

  const handleTurfCardClick = (index: number) => {
    const selectedPackage = data.areaPrice[index];
    setArea(selectedPackage.area);
    setPrice(selectedPackage.price.weekday.regularHours);
    let baseURL = generateUrl(userName);
    window.location.href = `${baseURL}?area=${selectedPackage.area}&price=${selectedPackage.price.weekday.regularHours}`;
  };

  return (
    <Row
      className="card-container-mobile"
      gutter={[8, 8]}
      onClick={(e) => !showPackages && handleCardClick(userName, e)}
    >
      <Col xs={24} sm={8}>
      <LazyLoadImage
          src={imageUrl[0]}
          alt={imgMeta}
          title={imgMeta}
          width={"100%"}
          height={"150px"}
        />
        <a
          href=""
          title={imgMeta}
          className="more-photos"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Photos
        </a>
      </Col>
      <Col xs={24} sm={16}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Rate
            style={{
              fontSize: 12,
            }}
            value={rating}
            allowHalf
            disabled
          />
          {data.partnership && (
            <div className="partnership-flag">Partnered</div>
          )}
        </Row>
        <Title ellipsis level={5} className="mt-0 mb-0">
          {name}
        </Title>
        {!showPackages && (
          <MobileCardInfo
            fields={req_fields}
            data={data}
            name={name}
            rating={rating}
            handleArea={handleArea}
            userName={userName}
          />
        )}
      </Col>
      {showBookNowButton && (
        <Col xs={24}>
          <Button
            type="primary"
            block={true}
            size="large"
            onClick={(e) => handleBookNow(e, area, price)}
          >
            Check Availability
          </Button>
        </Col>
      )}
      {showPackages && (
        <Col xs={24}>
          <div className="packages-container">
            {data.areaPrice.map((el: any, i: any) => (
              <div
                key={i}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent redirection when package is clicked
                  handleTurfCardClick(i);
                }}
              >
                <Card bordered>
                  <Title level={5}>Pitch available - {el.area} sq.ft</Title>
                  <Title level={5}>L*B*H - {el.pitchDimensions?.length}*{el.pitchDimensions?.width}*{el.pitchDimensions?.height} ft</Title>
                  <div className="area-container">
                    <Title level={5}>
                      Starting @ {el.price.weekday?.regularHours}/-
                    </Title>
                    {showBookNow(city) && (
                      <Button
                        type="primary"
                        block={true}
                        // size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleBookNow(
                            e,
                            el.area,
                            el.price.weekday?.regularHours
                          );
                        }}
                      >
                        Check Availability
                      </Button>
                    )}
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </Col>
      )}
      {showPackagesButton && (
        <>
          {!showPackages ? (
            <Col xs={24}>
              <Button
                block
                type="primary"
                size="large"
                onClick={handleShowPackages}
                style={{ marginTop: "12px" }}
              >
                Show Packages ({data.areaPrice.length})
              </Button>
            </Col>
          ) : (
            <Col xs={24}>
              <Button
                type="link"
                onClick={handleShowPackages}
                style={{
                  display: "block",
                  color: "#1890ff",
                  textAlign: "center",
                  fontSize: "1.3em",
                  paddingLeft: "80px",
                }}
                className="back-to-overview"
              >
                <Link to="#" className="back-to-overview-link">
                  {" "}
                  &lt; back to overview{" "}
                </Link>
              </Button>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default MobileCard;

// import "./index.scss";
// import React, { MouseEvent, useState, useEffect } from "react";
// import { Button, Col, Rate, Row, Typography, Card } from "antd";
// import MobileCardInfo from "../../pages/HomePage/TileContainer/TurfInfoList/MobileCardInfo";
// import { showBookNow } from "../../utils/showFeatures";
// import { Link } from "react-router-dom";  // Import Link from react-router-dom

// const MobileCard = ({ name, userName, imageUrl, fields, data, city }: any) => {
//   const [area, setArea] = useState(data.areaPrice[0].area);
//   const [price, setPrice] = useState(data.areaPrice[0].price.weekday.regularHours);
//   const [showPackages, setShowPackages] = useState(false);

//   const { Title } = Typography;

//   const req_fields = fields.filter((field: { key: string }) => {
//     const contact = data.partnership ? "actualContact" : "displayContact";
//     return (
//       field.key !== "availabilityData" &&
//       field.key !== "isRainProtected" &&
//       field.key !== "location" &&
//       field.key !== "address" &&
//       field.key !== "hasCafe" &&
//       field.key !== contact
//     );
//   });

//   const generateUrl = (userName: string) => {
//     const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
//     let subdomain = userName;
//     const domain = process.env.REACT_APP_DOMAIN || "turfbooking.in";
//     let baseURL = `${protocol}://`;
//     if (subdomain) {
//       baseURL += `${subdomain}.`;
//     }
//     baseURL += domain;
//     return baseURL;
//   };

//   const handleCardClick = (userName: string) => {
//     // Determine the protocol
//     let baseURL = generateUrl(userName);
//     window.location.href = `${baseURL}`;
//   };

//   const handleBookNow = (e: MouseEvent<HTMLElement>, selectedArea: string, selectedPrice: string) => {
//     e.stopPropagation();
//     let baseURL = generateUrl(userName);
//     window.location.href = `${baseURL}/booking?area=${selectedArea}&price=${selectedPrice}`;
//   };

//   const rating = data.starRating ? parseFloat(data.starRating) : 0;
//   const imgMeta = ` ${userName} | ${city} | turfbooking`;

//   const handleArea = (e: any) => {
//     setArea(e.area);
//     setPrice(e.price.weekday.regularHours);
//     setShowPackages(!showPackages);
//     console.log("Selected Package Price:", e.price.weekday.regularHours);
//   };

// useEffect(() => {
//   console.log("Updated Area:", area);
//   console.log("Updated Price:", price);
// }, [area, price]);

//   const showPackagesButton = data.areaPrice.length > 1;
//   const showBookNowButton = showBookNow(city) && !showPackagesButton;

//   const handleShowPackages = (e: MouseEvent) => {
//     e.stopPropagation();
//     setShowPackages(!showPackages);
//   };

//   const handleTurfCardClick = (index: number) => {
//     const selectedPackage = data.areaPrice[index];
//     setArea(selectedPackage.area);
//     setPrice(selectedPackage.price.weekday.regularHours);
//     console.log("Selected Package:", selectedPackage);
//     let baseURL = generateUrl(userName);
//     window.location.href = `${baseURL}?area=${selectedPackage.area}&price=${selectedPackage.price.weekday.regularHours}`;
//   };

//   return (
//     <Row
//       className="card-container-mobile"
//       gutter={[8, 8]}
//       onClick={() => !showPackages && handleCardClick(userName)}
//     >
//       <Col xs={24} sm={8}>
//         <img
//           src={imageUrl[0]}
//           alt={imgMeta}
//           title={imgMeta}
//           width={"100%"}
//           height={"150px"}
//         />
//         <a
//           href=""
//           title={imgMeta}
//           className="more-photos"
//           onClick={(e) => {
//             e.preventDefault();
//           }}
//         >
//           Photos
//         </a>
//       </Col>
//       <Col xs={24} sm={16}>
//         <Row style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
//           <Rate
//             style={{
//               fontSize: 12,
//             }}
//             value={rating}
//             allowHalf
//             disabled
//           />
//           {data.partnership && (
//             <div className="partnership-flag">Partnered</div>
//           )}
//         </Row>
//         <Title ellipsis level={5} className="mt-0 mb-0">
//           {name}
//         </Title>
//         <MobileCardInfo
//           fields={req_fields}
//           data={data}
//           name={name}
//           rating={rating}
//           handleArea={handleArea}
//           userName={userName}
//         />
//       </Col>
//       {showBookNowButton && (
//         <Col xs={24}>
//           <Button
//             type="primary"
//             block={true}
//             size="large"
//             onClick={(e) => handleBookNow(e, area, price)}
//           >
//             Check Availability
//           </Button>
//         </Col>
//       )}
//       {showPackages && (
//         <Col xs={24}>
//           <div  className="packages-container">
//             {data.areaPrice.map((el: any, i: any) => (
//               <div key={i} onClick={(e) => {
//                 e.stopPropagation(); // Prevent redirection when package is clicked
//                 handleTurfCardClick(i);
//               }}>
//                 <Card bordered>
//                   <p>Pitch available - {el.area} sq.ft</p>
//                   <p>Starting @ {el.price.weekday?.regularHours}/-</p>
//                   <Button
//                     type="primary"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       e.stopPropagation();
//                       handleBookNow(e, el.area, el.price.weekday?.regularHours);
//                     }}
//                   >
//                     Check Availability
//                   </Button>
//                 </Card>
//               </div>
//             ))}
//           </div>
//         </Col>
//       )}
//       {showPackagesButton && (
//         <>
//           {!showPackages ? (
//             <Col xs={24}>
//               <Button block type="primary" size="large" onClick={handleShowPackages} style={{ marginTop: "12px" }}>
//                 Show Packages ({data.areaPrice.length})
//               </Button>
//             </Col>
//           ) : (
//             <Col xs={24}>
//               <Button
//                 type="link"
//                 onClick={handleShowPackages}
//                 style={{ display: 'block', padding: 0, color: '#1890ff', textAlign: 'center', fontSize: '1.3em' }}
//                 className="back-to-overview"
//               >
//                 <Link to="#" className="back-to-overview-link"> &lt; back to overview </Link>
//               </Button>
//             </Col>
//           )}
//         </>
//       )}
//     </Row>
//   );
// };

// export default MobileCard;
