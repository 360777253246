import { Typography } from "antd";
import "./index.scss";
import React from "react";

const { Text, Title } = Typography;
const PrivacyPolicyComponent = () => {
  return (
    <div className="privacy-policy-container">
      <div className="heading">
        <Title>Privacy Statement</Title>
        <Text>
          At Turf Booking, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines how we collect, use, and safeguard your data in
          relation to the features and functionality of our turf booking
          platform.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Information We Collect</Title>
        <Text>
          <Text strong>Personal Information:</Text> When you use our platform to
          search for and book turfs, we may collect personal information such as
          your name, email address, contact number, and billing details.
        </Text>

        <Text>
          <Text strong>Usage Data:</Text> We automatically collect information
          about your interactions with our platform, including your browsing
          history, search queries, and preferences.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>How We Use Your Information</Title>
        <Text>
          <Text strong>Facilitating Turf Bookings:</Text> We use your personal
          information to facilitate the booking process, including confirming
          reservations, providing booking confirmations, and processing
          payments.
        </Text>
        <Text>
          <Text strong>Improving User Experience:</Text> We analyze usage data
          to improve the functionality and usability of our platform, ensuring a
          seamless experience for our users.
        </Text>

        <Text>
          <Text strong>Communication:</Text> We may use your contact information
          to communicate with you about your bookings, provide updates on new
          turfs or features, and respond to your inquiries.
        </Text>
      </div>
      <div className="section">
        <Title level={4}>Data Security</Title>

        <Text>
          We employ industry-standard security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction.
        </Text>

        <Text>
          Your payment information is encrypted and processed securely through
          trusted payment gateways to ensure the confidentiality of your
          financial data.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Third-Party Services</Title>

        <Text>
          We may use third-party service providers to assist us in delivering
          our services, such as payment processors or analytics providers. These
          third parties are contractually obligated to protect your information
          and are prohibited from using it for any other purpose.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Data Retention</Title>

        <Text>
          We retain your personal information only for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Your Rights</Title>

        <Text>
          You have the right to access, update, or delete your personal
          information at any time. You may also opt out of receiving promotional
          communications from us by following the instructions provided in such
          communications.
        </Text>
      </div>
      <div className="section">
        <Title level={4}>Children's Privacy</Title>

        <Text>
          Our platform is not intended for use by individuals under the age of
          18. We do not knowingly collect personal information from children,
          and if we become aware that we have inadvertently collected such
          information, we will take steps to delete it immediately.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Changes to This Privacy Policy</Title>

        <Text>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          revised policy on our website. We encourage you to review this Privacy
          Policy periodically for any updates.
        </Text>
      </div>

      <div className="section">
        <Title level={4}>Contact Us</Title>

        <Text>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at <a>info@turfbooking.in</a>.
        </Text>

        <Text>
          By using Turf Booking, you consent to the collection and use of your
          information as described in this Privacy Policy. Thank you for
          trusting us with your privacy.
        </Text>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
