import React from "react";
import "./index.scss";
import { Button, Flex, Timeline, Typography } from "antd";
import constants from "../../utils/constants";
import {
  CheckCircleOutlined,
  HomeOutlined,
  WhatsAppOutlined,
  QrcodeOutlined,
  SmileOutlined,
  FieldTimeOutlined,
  TrophyOutlined
} from "@ant-design/icons";

const SuccessPage = ({ name }: { name: string | null }) => {
  const { Paragraph, Title } = Typography;

  const handleHomeRedirect = () => {
    window.location.href = constants.HOST_URL;
  };

  return (
    <div className="booking-confirm-container">
      <div className="success-card">
        <div className="header">
          <Title level={2}>Thank You!</Title>
          <Flex vertical>
            <Title level={4}>
              You’ve successfully raised an enquiry for {name} from{' '}
              <span className="highlighted-text">TurfBooking.in</span>
            </Title>

            <Title level={1} style={{ fontSize: 128 }}>
              <FieldTimeOutlined style={{ color: '#52c41a' }} />
            </Title>



            <Timeline className="booking-timeline" mode="left">

              <Timeline.Item dot={<WhatsAppOutlined style={{ fontSize: '24px', color: '#25D366' }} />} color="blue">
                <Paragraph>
                  <strong>WhatsApp Confirmation:</strong> Our team will contact you via WhatsApp within the next 15 minutes to confirm the details of your booking.
                </Paragraph>
              </Timeline.Item>

              <Timeline.Item dot={<CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />} color="gray">
                <Paragraph>
                  <strong>Availability Check:</strong> Based on your request, we will check the availability with the turf.
                  <br />
                  <strong>Note:</strong> If the turf is unavailable, we will suggest other available options at the same time.
                </Paragraph>
              </Timeline.Item>

              <Timeline.Item dot={<QrcodeOutlined style={{ fontSize: '24px', color: '#1890ff' }} />} color="blue">
                <Paragraph>
                  <strong>Payment Process:</strong> If the booking is confirmed, you’ll receive a QR code via WhatsApp for an advance payment of ₹500.
                </Paragraph>
              </Timeline.Item>

              <Timeline.Item dot={<SmileOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />} color="red">
                <Paragraph>
                  <strong>Booking Confirmation:</strong> After we receive the payment, you’ll get a confirmation message with all the booking details on WhatsApp.
                </Paragraph>
              </Timeline.Item>

              <Timeline.Item dot={<TrophyOutlined style={{ fontSize: '24px', color: '#FFD700' }} />} color="green">
                <Paragraph>
                  <strong>Enjoy Your Time:</strong> Show up at the venue and enjoy your booking!
                </Paragraph>
              </Timeline.Item>

            </Timeline>
          </Flex>
        </div>

        <Flex wrap="wrap" justify="center" align="center" gap={8}>
          <Button
            shape="round"
            type="primary"
            href={constants.HOST_URL + "/feedback"}
            target="_blank"
          >
            Tell Us About Your Experience
          </Button>
          <Button shape="round" type="primary" onClick={handleHomeRedirect}>
            Home <HomeOutlined />
          </Button>
        </Flex>
      </div>
    </div>
  );
};

export default SuccessPage;
