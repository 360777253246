import React, { useState } from "react";

interface ShareButtonProps {
  className: string;
  latitude: string;
  longitude: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ className, latitude, longitude }) => {
  const shareLink = `https://maps.google.com/maps?q=${latitude},${longitude}`;

  const handleShare = async (): Promise<void> => {
    if (navigator.share) {
      try {
        navigator.share({
          title: "Share this Turf",
          url: shareLink,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      navigator.clipboard.writeText(shareLink);
      alert('Link copied to clipboard!');
    }
  }

  return (
    <div>
      <img className={className} src="/Icon/share-btn.svg" height={30} width={30} onClick={handleShare} />
    </div>
  );
};

export default ShareButton;